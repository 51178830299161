/* ==========================================================================
   #RESOURCES
   ========================================================================== */

.c-resources {
  margin-bottom: 50px;
}

.c-resources__search {
  margin-bottom: 40px;
}

.c-resources__search-form {
  max-width: 100%;
  width: 388px;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-resources__tags {
  margin-bottom: 70px;
  max-width: 830px;
}

.c-resources__tag {
  margin-right: 7px;
}

.c-resources__selected-tags {
  margin-bottom: 25px;
}

.c-resources__at-icon {
  vertical-align: -10%;
  margin-right: 5px;
}

.c-resources__at-button {
  font-size: 15px;
}

.c-resources__at-button--inner {
  display: none;
}

.c-resources__item-tags {
  display: none;
}

.c-resources__tag-title {
  position: relative;
  margin-bottom: 0.6em;
}

.c-resources__item-at {
  position: absolute;
  right: 100%;
  top: 0.3em;
  margin-right: 8px;
}

.c-resources__item {
  margin-top: 50px;
}

.c-resources__item-content {
  max-width: 440px;
}

.c-resources__nav-link {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.c-resources__items {
  margin-bottom: 50px;
}

.c-resources__clear {
  margin-top: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.c-resources__clear-icon {
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.5em;
}

@include bp-down(small) {
  .o-search__input {
    padding-right: 0;
  }
}

@include bp-down(medium) {
  .c-resources__tags-inner {
    margin-right: -30px;
    margin-left: -30px;
    padding-left: 30px;
    padding-right: 30px;
    overflow-x: scroll;
    position: relative;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding-bottom: 20px;
  }

  .c-resources__up {
    display: none;
  }
}

@include bp(medium) {
  .c-resources {
    margin-bottom: 60px;
  }

  .c-resources__tag {
    margin-right: 11px;
    margin-top: 15px;
  }

  .c-resources__at {
    display: none;
  }

  .c-resources__at-button--inner {
    display: inline-block;
    margin-top: 15px;
  }

  .c-resources__items {
    margin-bottom: 60px;
    position: relative;
  }

  .c-resources__up {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: auto;
  }

  .c-resources__search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .c-resources__clear {
    margin-top: 0;
    margin-left: 2em;
  }
}

@include bp(large) {
  .c-resources__search {
    margin-bottom: 60px;
  }

  .c-resources__item {
    display: flex;
    align-items: flex-start;
    margin-left: -(108 + 69) + px;
    margin-right: 0;
    width: 100%;
  }

  .c-resources__item-tags {
    display: block;
    width: 108px;
    margin-right: 69px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 5px;
  }

  .c-resources__item-tag {
    padding: 0.4em;
    border: 1px solid #cbcbcc;
    text-align: center;
    line-height: 1.2;
    border-radius: (14/13) + em;
    margin-bottom: 1em;
  }

  .c-resources__item-content {
    flex-grow: 1;
  }
}
