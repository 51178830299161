/* ==========================================================================
   #ROOMS
   ========================================================================== */

.c-rooms__text {
  margin-bottom: 30px;
}

.c-rooms__media {
  margin-bottom: 25px;
  position: relative;
}

.c-rooms__item--small .c-rooms__media {
  padding-top: (223/335) * 100%;
  margin-bottom: 11px;
}

.c-rooms__separator {
  margin-top: 30px;
  margin-bottom: 30px;
}

.c-rooms__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-rooms__item--small .c-rooms__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-rooms__button {
  font-size: 45px;
  width: 1em;
  height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f3f2ef;
  box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.36);
  transition: background-color 0.3s, color 0.3s;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.c-rooms__item--small .c-rooms__button {
  font-size: 35px;
}

.c-rooms__button-icon {
  width: (11/45) + em;
  height: (11/45) + em;
  display: block;
}

.c-rooms__title {
  margin-bottom: 11px;
}

.c-rooms__item {
  margin-bottom: 30px;
}

@include bp-down(medium) {
  .c-rooms__group {
    white-space: nowrap;
    margin-left: -30px;
    margin-right: -30px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
    margin-bottom: -30px;
  }

  .c-rooms__item--small {
    display: inline-block;
    width: 250px;
    margin-left: 17px;
    white-space: normal;
  }

  .c-rooms__item--small:first-child {
    margin-left: 30px;
  }

  .c-rooms__item--small:last-child {
    margin-right: 30px;
  }

  .c-rooms .o-block__sidebar {
    margin-top: 40px;
  }
}

@include bp(medium) {
  .c-rooms__separator {
    display: none;
  }

  .c-rooms__group {
    display: flex;
    flex-wrap: wrap;
    margin-left: -17px;
  }

  .c-rooms__item--small {
    width: calc(50% - 17px);
    margin-left: 17px;
  }
}

@include bp(medium) {
  .c-rooms__items {
    margin-bottom: -30px;
  }
}

@include bp(xlarge) {
  .c-rooms__item--small {
    width: calc(50% - 20px);
    margin-left: 20px;
  }

  .c-rooms__group {
    margin-left: -20px;
  }

  .c-rooms__item--small .c-rooms__button {
    font-size: 45px;
  }
}
