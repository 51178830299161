@charset "UTF-8";
/*
  Project: SF LGBT Center
  Author: Xfive
 */
/* Fonts
   ========================================================================== */
/* Sizes
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* CSS variables
   ========================================================================== */
:root {
  --initial-height: 100vh;
  --window-height: 100vh;
  --scroll-width: 0;
  --admin-bar: 32px;
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1em;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 2em;
}

/* ==========================================================================
   #BODY
   ========================================================================== */
body {
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

html.is-locked body {
  overflow: hidden;
}

html.is-locked.ie11 body {
  padding-right: 17px;
}

@supports (color: var(--var)) {
  html.is-locked body {
    padding-right: var(--scroll-width);
  }
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #HTML
   ========================================================================== */
html {
  background: #fff;
  color: #31333d;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.4375;
  min-height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

html.is-locked body {
  overflow: hidden;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/* ==========================================================================
   #INPUT
   ========================================================================== */
button, input, textarea, select {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
}

input, textarea, select {
  line-height: normal;
}

button {
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  line-height: inherit;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: currentColor;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: currentColor;
  text-decoration: none;
  transition: color 0.3s;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #SVG
   ========================================================================== */
svg {
  fill: currentColor;
  transition: fill 0.3s, stroke 0.3s;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #ANCHOR
   ========================================================================== */
.o-anchor {
  margin-bottom: 15px;
  line-height: 1;
  position: relative;
}

.o-anchor__target {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  top: -126px;
  visibility: hidden;
  pointer-events: none;
}

.o-anchor.is-hidden {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .o-anchor {
    margin-bottom: 20px;
  }
}

/* ==========================================================================
   #BLOCK
   ========================================================================== */
.o-block {
  margin-bottom: 40px;
}

.o-block__cta,
.o-block__text,
.o-block__title {
  margin-bottom: 15px;
}

.o-block__cta {
  max-width: 270px;
  width: 100%;
}

.o-block__column {
  max-width: 660px;
}

.o-block__sidebar:empty {
  display: none;
}

.c-block__sidebar-text {
  max-width: 210px;
  padding-right: 10px;
}

.c-block__sidebar-text hr {
  margin-right: -10px;
}

@media (min-width: 768px) {
  .o-block {
    margin-bottom: 50px;
  }
  .o-block__text {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) {
  .o-block__columns {
    display: flex;
    align-items: flex-start;
  }
  .o-block__column {
    width: calc(100% - 300px);
  }
  .o-block__sidebar {
    width: 270px;
    margin-left: auto;
    margin-top: 2px;
  }
}

/* ==========================================================================
   #BLOCKQUOTE
   ========================================================================== */
.o-blockquote, .o-text blockquote {
  font-size: 30px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  margin-left: 16px;
  margin-right: 16px;
}

.o-blockquote p, .o-text blockquote p {
  box-decoration-break: clone;
  display: inline;
  background-color: #6613c9;
  box-shadow: -16px 0 0 5px #6613c9, 16px 0 0 5px #6613c9;
}

@media (min-width: 768px) {
  .o-blockquote, .o-text blockquote {
    font-size: 35px;
    line-height: 60px;
  }
  .o-blockquote p, .o-text blockquote p {
    box-shadow: -16px 0 0 2px #6613c9, 16px 0 0 2px #6613c9;
    border-bottom: 4px solid #6613c9;
  }
}

@media (min-width: 1024px) {
  .o-blockquote, .o-text blockquote {
    margin-left: -165px;
    margin-right: 10px;
  }
}

/* ==========================================================================
   #BR
   ========================================================================== */
@media (max-width: 479.98px) {
  .o-no-br-small br {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .o-no-br-medium br {
    display: none;
  }
}

@media (max-width: 1023.98px) {
  .o-no-br-large br {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .o-no-br-xlarge br {
    display: none;
  }
}

/* ==========================================================================
   #BUTTON OVAL
   ========================================================================== */
.o-btn-oval {
  width: 60px;
  height: 60px;
  font-size: 30px;
  box-shadow: 0 0.06667em 0.13333em rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-color: #fff;
}

.o-btn-oval:not(input),
.o-btn-oval:not(button) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.o-btn-oval__icon {
  display: block;
  width: 1em;
  height: 1em;
  margin: auto;
}

html.ie11 .o-btn-oval__icon {
  margin: 0;
}

@media (max-width: 1199.98px) {
  .o-btn-oval--small {
    width: 32px;
    height: 32px;
    font-size: 20px;
  }
  .o-btn-oval--small .o-btn-oval__icon {
    margin-top: 4px;
  }
}

/* ==========================================================================
   #BUTTON PILL
   ========================================================================== */
.o-btn-pill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 99px;
  font-size: 13px;
  line-height: 1;
  padding: 0.76923em 1em;
  border-radius: 1.30769em;
}

.o-btn-pill--big {
  font-size: 16px;
  min-width: 120px;
  border-radius: 1.25em;
}

@media (min-width: 1200px) {
  .o-btn-pill {
    font-size: 15px;
    padding: 0.83333em 1.13333em;
    border-radius: 1.33333em;
  }
  .o-btn-pill--big {
    font-size: 16px;
  }
}

/* ==========================================================================
   #BUTTON
   ========================================================================== */
.o-btn {
  border: 2px solid currentColor;
  border-radius: 3px;
  line-height: 22/16;
  font-weight: 700;
  transition: color 0.3s;
  min-width: 105px;
  padding: 9px 15px;
  text-align: center;
}

.o-btn:not(input):not(button) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* ==========================================================================
   #CIRCLE BUTTON
   ========================================================================== */
.o-circle-button {
  display: block;
  width: 106px;
  height: 106px;
  position: relative;
}

.no-touch .o-circle-button:hover img, .o-circle-button.is-active img, .o-circle-button:active img, .o-circle-button:focus img {
  transform: scale(1.1);
}

.o-circle-button img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.no-touch .o-circle-button--nolink:hover img, .o-circle-button--nolink.is-active img, .o-circle-button--nolink:active img, .o-circle-button--nolink:focus img {
  transform: none;
}

.o-circle-button--big {
  width: 157px;
  height: 157px;
}

@media (min-width: 768px) {
  .o-circle-button {
    width: 157px;
    height: 157px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .o-circle-button--small {
    width: 126px;
    height: 126px;
  }
}

/* ==========================================================================
   #COLUMN
   ========================================================================== */
.o-column {
  max-width: 660px;
}

/* ==========================================================================
   #HAMBURGER
   ========================================================================== */
.o-hamburger__wrapper {
  display: block;
  width: 25px;
  height: 23px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.o-hamburger__bar {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: currentColor;
  opacity: 1;
  right: 0;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out, width 0.25s ease-in-out, height 0.25s ease-in-out, top 0.25s ease-in-out, right 0.25s ease-in-out;
  border-radius: 1.5px;
}

.o-hamburger__bar:nth-child(1) {
  top: 0;
}

.o-hamburger__bar:nth-child(2), .o-hamburger__bar:nth-child(3) {
  top: 10px;
  width: 20px;
}

.o-hamburger__bar:nth-child(4) {
  top: 20px;
}

.is-menu-open .o-hamburger__bar:nth-child(1) {
  top: 10px;
  width: 0;
  right: 50%;
}

.is-menu-open .o-hamburger__bar:nth-child(2), .is-menu-open .o-hamburger__bar:nth-child(3) {
  width: 30px;
  right: -3px;
}

.is-menu-open .o-hamburger__bar:nth-child(2) {
  transform: rotate(45deg);
}

.is-menu-open .o-hamburger__bar:nth-child(3) {
  transform: rotate(-45deg);
}

.is-menu-open .o-hamburger__bar:nth-child(4) {
  top: 10px;
  width: 0;
  right: 50%;
}

/* ==========================================================================
   #ICON BUTTON
   ========================================================================== */
.o-icon-button {
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.160666);
  border-radius: 3px;
  padding: 25px 20px 43px;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.o-icon-button--one-line {
  padding: 12px 50px 12px 20px;
}

.o-icon-button__icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 18px;
  flex-grow: 0;
  display: block;
}

.o-icon-button__content {
  flex-grow: 1;
  flex-shrink: 1;
  width: calc(100% - 68px);
}

.o-icon-button__title {
  margin-bottom: 6px;
}

.o-icon-button__text {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.o-icon-button__arrow {
  display: block;
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: 0;
  position: absolute;
  bottom: 18px;
  right: 20px;
}

@media (max-width: 1023.98px) {
  .o-icon-button--small {
    padding: 8px 33px 8px 14px;
  }
  .o-icon-button--small .o-icon-button__arrow {
    width: 8px;
    height: 8px;
    right: 14px;
    bottom: 14px;
  }
  .o-icon-button--small .o-icon-button__icon {
    width: 35px;
    height: 35px;
    margin-right: 11px;
  }
}

/* ==========================================================================
   #ICON
   ========================================================================== */
.o-icon-play {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 52px;
  height: 52px;
  margin-left: -26px;
  margin-top: -26px;
}

.o-icon-corner {
  width: 12px;
  height: 12px;
  display: inline-block;
  fill: #01b3ad;
  vertical-align: middle;
  margin-left: 10px;
}

@media (min-width: 1024px) {
  .o-icon-play:not(.o-icon-play--small) {
    width: 72px;
    height: 72px;
    margin-left: -36px;
    margin-top: -36px;
  }
}

/* ==========================================================================
   #INPUT
   ========================================================================== */
.o-input {
  display: block;
  position: relative;
  color: #6613c9;
  margin-bottom: 15px;
}

.o-input-submit {
  line-height: normal;
  height: 45px;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  padding: 10px 1em;
  border-radius: 2px;
  background-color: #01b3ad;
  color: #fff;
  transition: background-color 0.3s;
  display: block;
  width: 100%;
}

.no-touch .o-input-submit:hover, .o-input-submit.is-active, .o-input-submit:active, .o-input-submit:focus {
  background-color: #ff80ff;
}

.o-input__input {
  border: 1px solid currentColor;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: transparent;
  line-height: normal;
  font-size: 17px;
  padding: 10px 1em;
  display: block;
  width: 100%;
  height: 45px;
  outline: none;
  transition: border-color 0.3s;
}

.o-input__input.is-invalid {
  border-color: #ff80ff;
}

.o-input__label {
  font-size: 17px;
  padding: 10px 1em;
  line-height: normal;
  letter-spacing: -0.01176em;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
}

.o-input__input[data-value]:not([data-value='']) + .o-input__label,
.o-input__input:valid + .o-input__label,
.o-input__input:focus + .o-input__label {
  display: none;
}

.o-input__input:focus {
  border-color: #01b3ad;
}

.o-input__input[required] + .o-input__label:after {
  content: '*';
  color: #ff80ff;
}

.o-input--white .o-input__input,
.o-input--white .o-input__label {
  color: #f3f2ef;
}

.o-input--white .o-input {
  border-color: #fff;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
.o-link-pink {
  color: #ff80ff;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-pink,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-pink:hover, .o-link-pink.is-active, .o-link-pink:active, .o-link-pink:focus {
  color: #81f7f3;
}

.o-link-pink-light {
  color: #e1d1eb;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-pink-light,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-pink-light:hover, .o-link-pink-light.is-active, .o-link-pink-light:active, .o-link-pink-light:focus {
  color: #01b3ad;
}

.o-link-pink-lighter {
  color: #f7aecb;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-pink-lighter,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-pink-lighter:hover, .o-link-pink-lighter.is-active, .o-link-pink-lighter:active, .o-link-pink-lighter:focus {
  color: #fff;
}

.o-link-pink-lighter-pink {
  color: #f7aecb;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-pink-lighter-pink,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-pink-lighter-pink:hover, .o-link-pink-lighter-pink.is-active, .o-link-pink-lighter-pink:active, .o-link-pink-lighter-pink:focus {
  color: #ff80ff;
}

.o-link-pink-lighter-teal-lighter {
  color: #f7aecb;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-pink-lighter-teal-lighter,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-pink-lighter-teal-lighter:hover, .o-link-pink-lighter-teal-lighter.is-active, .o-link-pink-lighter-teal-lighter:active, .o-link-pink-lighter-teal-lighter:focus {
  color: #81f7f3;
}

.o-link-purple, .o-link-default-purple a:not([class]) {
  color: #6613c9;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-purple, .no-touch .o-link-parent:hover .o-link-default-purple a:not([class]), .o-link-default-purple .no-touch .o-link-parent:hover a:not([class]),
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-purple:hover,
.no-touch .o-link-default-purple a:hover:not([class]),
.o-link-default-purple .no-touch a:hover:not([class]), .o-link-purple.is-active, .o-link-default-purple a.is-active:not([class]), .o-link-purple:active, .o-link-default-purple a:active:not([class]), .o-link-purple:focus, .o-link-default-purple a:focus:not([class]) {
  color: #01b3ad;
}

.o-link-purple-white {
  color: #6613c9;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-purple-white,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-purple-white:hover, .o-link-purple-white.is-active, .o-link-purple-white:active, .o-link-purple-white:focus {
  color: #fff;
}

.o-link-teal, .o-link-default-teal a:not([class]), .o-twitter a {
  color: #01b3ad;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-teal, .no-touch .o-link-parent:hover .o-link-default-teal a:not([class]), .o-link-default-teal .no-touch .o-link-parent:hover a:not([class]), .no-touch .o-link-parent:hover .o-twitter a, .o-twitter .no-touch .o-link-parent:hover a,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-teal:hover,
.no-touch .o-link-default-teal a:hover:not([class]),
.o-link-default-teal .no-touch a:hover:not([class]),
.no-touch .o-twitter a:hover,
.o-twitter .no-touch a:hover, .o-link-teal.is-active, .o-link-default-teal a.is-active:not([class]), .o-twitter a.is-active, .o-link-teal:active, .o-link-default-teal a:active:not([class]), .o-twitter a:active, .o-link-teal:focus, .o-link-default-teal a:focus:not([class]), .o-twitter a:focus {
  color: #6613c9;
}

.o-link-teal-white {
  color: #01b3ad;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-teal-white,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-teal-white:hover, .o-link-teal-white.is-active, .o-link-teal-white:active, .o-link-teal-white:focus {
  color: #fff;
}

.o-link-teal-lighten {
  color: #01b3ad;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-teal-lighten,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-teal-lighten:hover, .o-link-teal-lighten.is-active, .o-link-teal-lighten:active, .o-link-teal-lighten:focus {
  color: #81f7f3;
}

.o-link-white {
  color: #fff;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-white,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-white:hover, .o-link-white.is-active, .o-link-white:active, .o-link-white:focus {
  color: #ff80ff;
}

.o-link-white-pink-lighter {
  color: #fff;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-white-pink-lighter,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-white-pink-lighter:hover, .o-link-white-pink-lighter.is-active, .o-link-white-pink-lighter:active, .o-link-white-pink-lighter:focus {
  color: #f7aecb;
}

.o-link-grey {
  color: #aeafb4;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-grey,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-grey:hover, .o-link-grey.is-active, .o-link-grey:active, .o-link-grey:focus {
  color: #01b3ad;
}

.o-link-black {
  color: #000;
  transition: color 0.3s;
}

.no-touch .o-link-parent:hover .o-link-black,
.o-link-parent:active,
.o-link-parent:focus,
.no-touch .o-link-black:hover, .o-link-black.is-active, .o-link-black:active, .o-link-black:focus {
  color: #6613c9;
}

.o-link-calendar {
  color: #6613c9;
  background-color: #e1d1eb;
  font-weight: 700;
  transition: color 0.3s, background-color 0.3s;
  font-size: 15px;
  line-height: 20px;
  padding: 8px 15px;
  border-radius: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.no-touch .o-link-calendar:hover, .o-link-calendar.is-active, .o-link-calendar:active, .o-link-calendar:focus {
  color: #fff;
  background-color: #01b3ad;
}

.o-link-calendar__icon {
  width: 16px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  display: block;
  margin-right: 10px;
  margin-left: 3px;
}

.o-link-pill {
  transition: color 0.3s, background-color 0.3s;
  background-color: #ccf0ef;
}

.no-touch .o-link-pill:hover, .o-link-pill.is-active, .o-link-pill:active, .o-link-pill:focus {
  background-color: #6613c9;
}

.o-link-pill-purple {
  transition: color 0.3s, background-color 0.3s;
  background-color: #6613c9;
}

.no-touch .o-link-pill-purple:hover, .o-link-pill-purple.is-active, .o-link-pill-purple:active, .o-link-pill-purple:focus {
  background-color: #01b3ad;
}

.o-link-cta {
  display: inline-block;
  font-weight: 700;
  line-height: 1.1875;
  background: #01b3ad;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.385049);
  border-radius: 3px;
  transition: color 0.3s, background-color 0.3s;
  color: #fff;
  padding: 0.8em 1em 0.7em;
}

.no-touch .o-link-cta:hover, .o-link-cta.is-active, .o-link-cta:active, .o-link-cta:focus {
  background-color: #6613c9;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #SEARCH
   ========================================================================== */
.o-search {
  background-color: #f1eafa;
  align-items: stretch;
  position: relative;
  border-radius: 35px;
}

.o-search__input {
  height: 70px;
  padding: 0 24px;
  flex-grow: 1;
  flex-shrink: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 70px);
}

.o-search__submit {
  height: 70px;
  width: 70px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: auto;
  display: block;
}

/* ==========================================================================
   #SECTION MASK
   ========================================================================== */
.o-section-mask {
  position: relative;
}

.o-section-mask__bg,
.o-section-mask__bg-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.o-section-mask__bg {
  color: #fff;
}

.o-section-mask__content {
  position: relative;
}

.o-section-mask__top,
.o-section-mask__bottom {
  position: absolute;
  width: 100%;
  left: 0;
  display: block;
}

.o-section-mask__top {
  top: -1px;
}

.o-section-mask__bottom {
  bottom: -1px;
}

.o-section-mask--chevron {
  padding-bottom: 60px;
}

.o-section-mask--chevron .o-section-mask__bottom {
  height: 60px;
  min-width: 640px;
  right: 50%;
  left: auto;
  transform: translateX(50%);
}

.o-section-mask--skew .o-section-mask__bottom,
.o-section-mask--skew .o-section-mask__top {
  height: 100px;
}

.o-section-mask--skew .o-section-mask__bottom {
  transform: scale(-1, -1);
}

@media (max-width: 767.98px) {
  .o-section-mask--chevron .o-section-mask__bg {
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .o-section-mask--chevron {
    padding-bottom: 90px;
  }
  .o-section-mask--chevron .o-section-mask__bottom {
    height: 90px;
    width: 100%;
    right: auto;
    left: 0;
    transform: none;
  }
  .o-section-mask--skew .o-section-mask__bottom,
  .o-section-mask--skew .o-section-mask__top {
    height: 180px;
  }
}

@media (min-width: 1200px) {
  .o-section-mask--chevron {
    padding-bottom: 166px;
  }
  .o-section-mask--chevron .o-section-mask__bottom {
    height: 166px;
  }
  .o-section-mask--skew .o-section-mask__bottom,
  .o-section-mask--skew .o-section-mask__top {
    height: 260px;
  }
}

@media (min-width: 1400px) {
  .o-section-mask--skew .o-section-mask__bottom,
  .o-section-mask--skew .o-section-mask__top {
    height: 330px;
  }
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #TEXT
   ========================================================================== */
.o-text h1, .o-text h2, .o-text h3, .o-text h4, .o-text h5, .o-text h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-weight: inherit;
}

.o-text h1:first-child, .o-text h2:first-child, .o-text h3:first-child, .o-text h4:first-child, .o-text h5:first-child, .o-text h6:first-child {
  margin-top: 0;
}

.o-text h1 {
  font-size: 35px;
  line-height: 45px;
}

.o-text h2 {
  font-size: 30px;
  line-height: 37px;
}

.o-text h3 {
  font-size: 24px;
  line-height: 34px;
}

.o-text h4 {
  font-size: 22px;
}

.o-text h5 {
  font-size: 20px;
}

.o-text h6 {
  font-size: 18px;
}

.o-text p, .o-text ul, .o-text ol, .o-text blockquote, .o-text iframe, .o-text hr {
  margin-bottom: 22px;
}

.o-text img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 24px;
}

.o-text hr {
  border: none;
  height: 1px;
  background-color: #e5e5e5;
}

.o-text ul {
  list-style: none;
  margin-left: 0;
}

.o-text ul li {
  position: relative;
  padding-left: 1.2em;
}

.o-text ul li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}

.o-text iframe {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  min-height: 200px;
  border: none;
  overflow-x: hidden;
}

.o-text iframe:not([width]) {
  width: 100%;
}

@media (min-width: 768px) {
  .o-text blockquote {
    margin-top: 30px;
    margin-bottom: 34px;
  }
  .o-text h1 {
    font-size: 39px;
    line-height: 47px;
  }
}

@media (min-width: 1024px) {
  .o-text h1 {
    font-size: 48px;
    line-height: 54px;
  }
  .o-text h2 {
    font-size: 35px;
    line-height: 45px;
  }
}

/* ==========================================================================
   #TWITTER
   ========================================================================== */
.o-twitter {
  word-wrap: break-word;
}

.o-twitter .invisible {
  display: none;
}

.o-twitter .Emoji--forText {
  height: 1.25em;
  width: 1.25em;
  padding: 0 0.05em 0 0.1em;
  vertical-align: -0.2em;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  width: calc(100% - 60px);
  margin-right: auto;
  margin-left: auto;
  max-width: 1240px;
}

@media (min-width: 768px) {
  .o-wrapper {
    width: calc(100% - 80px);
  }
}

@media (min-width: 1024px) {
  .o-wrapper-padding {
    margin-left: 225px;
    margin-right: 69px;
  }
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* ==========================================================================
   #CONTENT
   ========================================================================== */
@media (max-width: 767.98px) {
  .c-content__menu {
    display: none;
  }
}

@media (min-width: 768px) {
  .c-content {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  }
  .c-content__menu {
    width: 190px;
    margin-right: 15px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-content__content {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 205px);
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .c-content__menu {
    width: 210px;
  }
  .c-content__content:first-child {
    margin-left: 225px;
  }
}

/* ==========================================================================
   #FEATURED
   ========================================================================== */
.c-featured {
  background-color: #6613c9;
  padding-top: 45px;
  padding-bottom: 45px;
}

.c-featured__form {
  width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  display: block;
  max-width: 596px;
}

.c-featured__submit {
  max-width: 170px;
  margin-left: auto;
  margin-right: auto;
}

.c-featured__tile {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  margin-bottom: 50px;
}

.c-featured__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-featured__content {
  padding: 42px 33px;
}

.c-featured__category {
  font-size: 14px;
  letter-spacing: 1.24444px;
  margin-bottom: 13px;
}

.c-featured__title {
  font-size: 22px;
  letter-spacing: -0.2px;
  margin-bottom: 0.5em;
}

.c-featured__text {
  margin-bottom: 1.5em;
}

.c-featured__form-message {
  width: 100%;
  margin-top: 20px;
}

.c-featured__form.is-success .c-featured__form-message {
  color: #81f7f3;
}

.c-featured__form-message:empty {
  display: none;
}

@media (min-width: 768px) {
  .c-featured__form {
    margin-top: -15px;
  }
  .c-featured__form-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .c-featured__form-row {
    width: calc((100% - 15px) / 2);
    margin-bottom: 0;
    margin-top: 15px;
  }
  .c-featured__submit {
    margin-right: 0;
  }
  .c-featured__tile {
    display: flex;
    align-items: stretch;
  }
  .c-featured__media {
    position: relative;
    min-height: 200px;
    width: 42.83604%;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .c-featured__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .c-featured__content {
    padding: 50px 34px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .c-featured__text {
    margin-bottom: 2em;
  }
}

@media (min-width: 1024px) {
  .c-featured__form {
    margin-top: 0;
    max-width: 1082px;
  }
  .c-featured__form-inner {
    flex-wrap: nowrap;
  }
  .c-featured__form-row {
    width: 26.80222%;
    flex-shrink: 1;
    flex-grow: 0;
    margin-top: 0;
    margin-right: 15px;
  }
  .c-featured__form-row:last-child {
    width: 167px;
    white-space: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0;
  }
  .c-featured__media {
    width: 51.66667%;
  }
}

@media (min-width: 1200px) {
  .c-featured {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .c-featured__content {
    padding: 60px 50px;
  }
  .c-featured__category {
    margin-bottom: 17px;
  }
  .c-featured__title {
    font-size: 26px;
  }
  .c-featured__text {
    font-size: 18px;
    margin-bottom: 40px;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  position: relative;
}

.c-footer__inner {
  padding: 70px 10px 40px;
}

.c-footer__link {
  display: block;
  width: 150px;
  height: 68px;
  margin-bottom: 40px;
}

.c-footer__logo {
  display: block;
  width: 100%;
  height: auto;
}

.c-footer__header,
.c-footer__bottom {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

.c-footer__menu {
  margin-bottom: 40px;
  display: flex;
  margin-right: -20px;
}

.c-footer__submenu {
  margin-bottom: 0;
  margin-top: 1em;
}

.c-footer__menu-subitem {
  margin-bottom: 1em;
}

.c-footer__menu-item {
  width: 50%;
  margin-right: 20px;
  margin-bottom: 0;
}

.c-footer__address {
  margin-bottom: 40px;
  letter-spacing: -0.2px;
}

.c-footer__address p {
  margin-bottom: 0.5em;
}

.c-footer__separator {
  height: 1px;
  border: none;
  background-color: rgba(70, 71, 71, 0.14);
  margin-bottom: 40px;
  margin-left: -23px;
  margin-right: -23px;
}

.c-footer__social {
  display: flex;
  font-size: 20px;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 30px;
}

.c-footer__social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.c-footer__social-icon {
  width: 1em;
  height: 1em;
  display: block;
  margin: auto;
}

.c-footer__copyright {
  line-height: 27px;
  font-size: 14px;
  letter-spacing: -0.2px;
  margin-right: -10px;
}

@media (min-width: 768px) {
  .c-footer__link {
    width: 122px;
    height: 56px;
  }
  .c-footer__header {
    display: flex;
    justify-content: space-between;
  }
  .c-footer__menu-item {
    margin-right: 0;
    width: auto;
    max-width: 49%;
  }
  .c-footer__menu {
    margin-right: 0;
    justify-content: space-between;
    width: 35.90909%;
    flex-shrink: 0;
  }
  .c-footer__separator {
    margin-left: 0;
    margin-right: 0;
  }
  .c-footer__social {
    font-size: 22px;
    margin-left: -9px;
    margin-top: -9px;
    margin-bottom: 21px;
  }
  .c-footer__social-link {
    margin-right: 3px;
  }
  .c-footer__copyright {
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .c-footer__inner {
    padding-left: 0;
    padding-right: 0;
  }
  .c-footer__address {
    width: 24.07407%;
    margin-bottom: 0;
  }
  .c-footer__logo {
    margin-bottom: 0;
  }
  .c-footer__header {
    margin-bottom: 55px;
  }
  .c-footer__menu {
    width: 34.44444%;
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .c-footer__link {
    width: 150px;
    height: 68px;
  }
}

@media (min-width: 1400px) {
  .c-footer__separator {
    width: 1330px;
    margin-left: -45px;
    margin-right: 0;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  height: 80px;
}

.c-header__wrapper {
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 15;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-bar .c-header__wrapper {
  top: 32px;
}

html.is-locked.ie11 .c-header__wrapper {
  padding-right: 17px;
}

@supports (color: var(--var)) {
  html.is-locked .c-header__wrapper {
    padding-right: var(--scroll-width);
  }
  .admin-bar .c-header__wrapper {
    top: var(--admin-bar);
  }
}

.c-header__inner {
  height: 80px;
  display: flex;
  align-items: center;
  justify-items: flex-start;
}

.c-header__hamburger {
  width: 41px;
  height: 41px;
  margin-right: -8px;
}

.c-header__link {
  margin-right: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-header__logo {
  display: block;
  width: 221px;
  height: 23px;
}

.c-header__overlay {
  position: fixed;
  z-index: 15;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #6613c9;
  transition: opacity 0.4s, visibility 0.4s;
  opacity: 0;
  visibility: hidden;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

.c-header__overlay::-webkit-scrollbar {
  width: 0;
  height: 0;
}

html:not(.is-menu-open) .c-header__overlay {
  pointer-events: none;
}

html:not(.is-menu-open) .c-header__overlay * {
  pointer-events: none;
}

body.admin-bar .c-header__overlay {
  height: calc(100% - 112px);
}

html.is-menu-open .c-header__overlay {
  opacity: 1;
  visibility: visible;
  -webkit-overflow-scrolling: touch;
}

html.is-locked.ie11 .c-header__overlay {
  padding-right: 17px;
}

@supports (color: var(--var)) {
  html.is-locked .c-header__overlay {
    padding-right: var(--scroll-width);
  }
  .c-header__overlay,
  body.admin-bar .c-header__overlay {
    height: calc(var(--inner-height) - 80px);
  }
}

.c-header__overlay-inner {
  padding-top: 70px;
  padding-bottom: 70px;
}

.c-header__address {
  margin-top: 55px;
  margin-bottom: 0;
}

.c-header__address-icon {
  width: 34px;
  height: 45px;
  fill: #f7aecb;
  display: block;
  margin-bottom: 10px;
}

.c-header__social {
  margin-top: 55px;
}

.c-header__social-link {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.c-header__social-links {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
}

.c-header__social-icon {
  font-size: 20px;
  width: 1em;
  height: 1em;
  display: block;
}

.c-header__bg-wrapper {
  display: none;
}

@media (max-width: 359.98px) {
  .c-header__logo {
    width: 143.65px;
    height: 14.95px;
  }
}

@media (min-width: 360px) and (max-width: 419.98px) {
  .c-header__logo {
    width: 165.75px;
    height: 17.25px;
  }
}

@media (min-width: 340px) and (max-width: 479.98px) {
  .c-header__button.c-header__button {
    margin-right: 10px;
  }
}

@media (max-width: 479.98px) {
  .c-header__button.c-header__button {
    padding: 0.4em 0.5em;
    min-width: 0;
    flex-shrink: 0;
  }
}

@media (min-width: 480px) {
  .c-header__button {
    margin-right: 36px;
  }
}

@media (min-width: 768px) {
  .c-header__logo {
    width: 241px;
    height: 25px;
  }
  .c-header__overlay-inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .c-header__social,
  .c-header__address {
    margin-top: 65px;
  }
  .c-header__social {
    margin-right: 0;
  }
  .c-header__social-link {
    margin-left: 9px;
    margin-right: 9px;
    margin-top: 13px;
  }
  .c-header__social-links {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -8px;
  }
  .c-header__social-icon {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .c-header__logo {
    width: 281px;
    height: 29px;
  }
  .c-header__overlay-inner {
    display: flex;
    align-items: flex-start;
  }
  .c-header__aside {
    width: 56.45161%;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .c-header__footer {
    margin-top: 45px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-right: -40px;
    width: 100%;
  }
  .c-header__menu {
    width: 43.54839%;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .c-header__bg-wrapper {
    position: relative;
    display: block;
    width: 100%;
  }
  .c-header__bg-wrapper:before {
    content: '';
    position: absolute;
    height: 100%;
    left: 100%;
    background-color: #8542d4;
    width: calc(50vw - 600px);
    min-width: 40px;
  }
  .c-header__bg {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }
  .c-header__social,
  .c-header__address {
    margin-top: 0;
  }
  .c-header__address {
    flex-shrink: 0;
    margin-right: auto;
  }
  .c-header__social {
    margin-right: 0;
    margin-left: auto;
    flex-grow: 0;
  }
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav,
.c-main-nav__dropdown {
  margin-bottom: 0;
  margin-top: 0;
}

.c-main-nav__item,
.c-main-nav__subitem {
  margin-bottom: 0;
}

.c-main-nav__trigger {
  padding: 0 6px;
  transition: opacity 0.3s, transform 0.3s;
  outline: none;
}

.c-main-nav__trigger.is-active {
  opacity: 0;
  transform: rotate(-80deg) scale(0.3);
  pointer-events: none;
}

.c-main-nav__dropdown {
  position: relative;
  transition: max-height 0.4s;
  overflow: hidden;
  max-height: 0;
}

.c-main-nav__item.is-active .c-main-nav__dropdown {
  max-height: 300px;
}

.c-main-nav__subitem:last-child {
  padding-bottom: 30px;
}

/* ==========================================================================
   #MAIN
   ========================================================================== */
.c-main {
  position: relative;
  z-index: 0;
}

/* ==========================================================================
   #OVAL HEADER
   ========================================================================== */
.c-oval-header {
  margin-top: 40px;
  margin-bottom: 30px;
}

.c-oval-header__header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.c-oval-header__image {
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 14px;
}

.c-oval-header__title {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .c-oval-header {
    margin-top: 50px;
  }
  .c-oval-header__image {
    width: 108px;
    height: 108px;
    margin-bottom: 20px;
  }
  .c-oval-header__header {
    display: block;
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) {
  .c-oval-header {
    margin-top: 150px;
  }
  .c-oval-header__header {
    position: relative;
  }
  .c-oval-header__image {
    width: 192px;
    height: 192px;
    position: absolute;
    top: -54px;
    right: 100%;
    margin-right: 35px;
    margin-bottom: 0;
  }
}

/* ==========================================================================
   #PARDOT FORM
   ========================================================================== */
.c-pardot-form .btn,
.c-pardot-form [type='submit'] {
  border-radius: 2px;
  background-color: #01b3ad;
  color: #fff;
  font-weight: bold;
  min-height: 45px;
  text-align: center;
  font-size: 17px;
  letter-spacing: -0.2px;
  padding: 0.5em 1em;
  width: 100%;
  transition: color 0.3s, background-color 0.3s;
}

.c-pardot-form .btn:not(button):not(input),
.c-pardot-form [type='submit']:not(button):not(input) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.no-touch .c-pardot-form .btn:hover, .c-pardot-form .btn.is-active, .c-pardot-form .btn:active, .c-pardot-form .btn:focus, .no-touch
.c-pardot-form [type='submit']:hover,
.c-pardot-form [type='submit'].is-active,
.c-pardot-form [type='submit']:active,
.c-pardot-form [type='submit']:focus {
  background-color: #ff80ff;
}

.c-pardot-form .submit.submit {
  margin: 40px 0 0 0;
}

.c-pardot-form .form-field {
  margin-left: 0;
  color: #6613c9;
  position: relative;
}

.c-pardot-form .form-field.no-label {
  margin-left: 0;
}

.c-pardot-form .form-field .field-label {
  color: currentColor;
}

.c-pardot-form .required .field-label:after {
  content: '*';
  color: #ff80ff;
}

.c-pardot-form input {
  max-width: 100%;
}

.c-pardot-form .form-field > input {
  width: 100%;
}

.c-pardot-form .form-field > input,
.c-pardot-form .form-field > input + .field-label {
  font-size: 18px;
  font-weight: 700;
  color: currentColor;
  padding-left: 1em;
  padding-right: 1em;
  line-height: normal;
}

.c-pardot-form .form-field > input {
  border-radius: 2px;
  border: 1px solid currentColor;
  height: 54px;
  position: relative;
}

.c-pardot-form .form-field > input + .field-label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.c-pardot-form .form-field > input[data-value]:not([data-value='']) + .field-label,
.c-pardot-form .form-field > input:focus + .field-label {
  display: none;
}

.c-pardot-form .pd-checkbox,
.c-pardot-form .pd-radio {
  font-size: 16px;
}

.c-pardot-form .pd-checkbox .value,
.c-pardot-form .pd-radio .value {
  position: relative;
  display: block;
}

.c-pardot-form .pd-checkbox .value input,
.c-pardot-form .pd-radio .value input {
  position: absolute;
  right: 100%;
  top: 0;
  visibility: hidden;
  appearance: none;
  pointer-events: none;
}

.c-pardot-form .pd-checkbox .value label,
.c-pardot-form .pd-radio .value label {
  position: relative;
  padding-left: 30px;
  min-height: 18px;
  display: block;
}

.c-pardot-form .pd-checkbox .value label:before,
.c-pardot-form .pd-radio .value label:before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid currentColor;
  position: absolute;
  left: 0;
  top: 0;
  transition: box-shadow 0.3s, background-color 0.3s;
}

.c-pardot-form .pd-checkbox .value input:checked + label:before,
.c-pardot-form .pd-radio .value input:checked + label:before {
  opacity: 1;
  background-color: #ff80ff;
  box-shadow: inset 0 0 0 3px #fff;
}

.c-pardot-form .errors {
  color: #6613c9;
}

.c-pardot-form .error.no-label {
  color: #ff80ff;
}

.c-pardot-form .error.no-label {
  margin-left: 0;
}

.c-pardot-form__inner {
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 40px);
}

/* ==========================================================================
   # POPUP
   ========================================================================== */
.c-popup {
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  background-color: rgba(1, 179, 173, 0.95);
  position: fixed;
  z-index: 5;
  overflow-y: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

.c-popup::-webkit-scrollbar {
  width: 0;
  height: 0;
}

html.is-locked.ie11 .c-popup {
  padding-right: 17px;
}

@supports (color: var(--var)) {
  html.is-locked .c-popup {
    padding-right: var(--scroll-width);
  }
  .c-popup {
    height: calc(var(--inner-height) - 80px);
  }
}

.c-popup--preview {
  position: relative;
  top: 0;
  left: 0;
  height: auto;
}

.c-popup:not(.is-active) {
  pointer-events: none;
}

.c-popup:not(.is-active) * {
  pointer-events: none;
}

.c-popup.is-active {
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  visibility: visible;
}

.c-popup__inner {
  width: calc(100% - 34px);
  max-width: 680px;
  margin: auto;
  position: relative;
  border-radius: 2px;
  background: #fff url("../assets/images/pic-pattern.svg") no-repeat top center;
  background-size: 670px 10px;
  padding-top: 10px;
  box-shadow: 2px 4px 18px rgba(35, 35, 37, 0.1);
}

.c-popup--video .c-popup__inner {
  max-width: 1240px;
}

.c-popup__content {
  margin: 60px 10px 30px;
}

.c-popup--video .c-popup__content {
  position: relative;
  margin: 40px 0 0 0;
  padding-top: 56.25%;
  background-color: #000;
}

.c-popup__close {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  top: -28px;
  left: 50%;
  margin-left: -28px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.17);
  line-height: 1;
  z-index: 1;
}

.c-popup__close-icon {
  display: block;
  margin: auto;
  vertical-align: middle;
}

.c-popup__footer {
  min-height: 40px;
  padding-bottom: 30px;
  padding-top: 10px;
}

.c-popup--video .c-popup__footer {
  padding-bottom: 10px;
  min-height: 45px;
}

.c-popup__close-footer {
  font-size: 14px;
}

.c-popup .c-popup__video.c-popup__video {
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.c-popup .c-popup__content iframe {
  display: none;
}

.c-popup.is-visible .c-popup__content iframe {
  display: block;
}

@media (orientation: landscape) {
  .c-popup--video .c-popup__inner {
    max-height: calc(100vh - 180px);
    width: calc((100vh - 275px) * 16/9);
  }
}

@media (min-width: 400px) {
  .c-popup__content {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (min-width: 700px) {
  .c-popup__inner {
    background-size: 100% 10px;
  }
  .c-popup__content {
    margin-left: 50px;
    margin-right: 50px;
  }
}

/* ==========================================================================
   #PROGRAMS
   ========================================================================== */
.c-programs {
  margin-bottom: 50px;
}

.c-programs__items {
  position: relative;
  max-width: 680px;
}

.c-programs__up {
  display: none;
}

.c-programs__item {
  border-bottom: 1px solid #e6e6d9;
  margin-bottom: 27px;
}

.c-programs__item:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}

.c-programs__title {
  margin-bottom: 3px;
}

.c-programs__text {
  margin-bottom: 17px;
}

.c-programs__media {
  position: relative;
  display: block;
}

.c-programs__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-programs__media[style] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-programs__bottom {
  height: 46px;
  text-align: right;
  padding-top: 10px;
}

.c-programs__button {
  margin-bottom: 40px;
  min-width: 187px;
  white-space: nowrap;
}

.c-programs__item:last-of-type .c-programs__button {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .c-programs__items {
    width: calc(100% - 210px);
  }
  .c-programs__item {
    position: relative;
    margin-bottom: 34px;
  }
  .c-programs__title {
    margin-bottom: 13px;
  }
  .c-programs__footer {
    position: relative;
  }
  .c-programs__button {
    position: absolute;
    left: calc(100% + 20px);
    top: 0;
    margin-bottom: 0;
  }
  .c-programs__text {
    margin-bottom: 27px;
  }
  .c-programs__bottom {
    padding-top: 18px;
    height: auto;
    padding-bottom: 22px;
  }
  .c-programs__transcript.c-programs__transcript {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .c-programs {
    margin-bottom: 120px;
  }
  .c-programs__items {
    margin-top: 70px;
    position: relative;
  }
  .c-programs__button {
    min-width: 240px;
  }
  .c-programs__up {
    display: block;
    position: absolute;
    left: calc(100% + 20px);
    bottom: 62px;
    min-width: 0;
    white-space: nowrap;
  }
}

@media (min-width: 1200px) {
  .c-programs__items {
    width: calc(100% - 222px);
  }
  .c-programs__button {
    left: calc(100% + 32px);
  }
  .c-programs__up {
    left: calc(100% + 32px);
  }
}

/* ==========================================================================
   #RESOURCES
   ========================================================================== */
.c-resources {
  margin-bottom: 50px;
}

.c-resources__search {
  margin-bottom: 40px;
}

.c-resources__search-form {
  max-width: 100%;
  width: 388px;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-resources__tags {
  margin-bottom: 70px;
  max-width: 830px;
}

.c-resources__tag {
  margin-right: 7px;
}

.c-resources__selected-tags {
  margin-bottom: 25px;
}

.c-resources__at-icon {
  vertical-align: -10%;
  margin-right: 5px;
}

.c-resources__at-button {
  font-size: 15px;
}

.c-resources__at-button--inner {
  display: none;
}

.c-resources__item-tags {
  display: none;
}

.c-resources__tag-title {
  position: relative;
  margin-bottom: 0.6em;
}

.c-resources__item-at {
  position: absolute;
  right: 100%;
  top: 0.3em;
  margin-right: 8px;
}

.c-resources__item {
  margin-top: 50px;
}

.c-resources__item-content {
  max-width: 440px;
}

.c-resources__nav-link {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.c-resources__items {
  margin-bottom: 50px;
}

.c-resources__clear {
  margin-top: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.c-resources__clear-icon {
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.5em;
}

@media (max-width: 479.98px) {
  .o-search__input {
    padding-right: 0;
  }
}

@media (max-width: 767.98px) {
  .c-resources__tags-inner {
    margin-right: -30px;
    margin-left: -30px;
    padding-left: 30px;
    padding-right: 30px;
    overflow-x: scroll;
    position: relative;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding-bottom: 20px;
  }
  .c-resources__up {
    display: none;
  }
}

@media (min-width: 768px) {
  .c-resources {
    margin-bottom: 60px;
  }
  .c-resources__tag {
    margin-right: 11px;
    margin-top: 15px;
  }
  .c-resources__at {
    display: none;
  }
  .c-resources__at-button--inner {
    display: inline-block;
    margin-top: 15px;
  }
  .c-resources__items {
    margin-bottom: 60px;
    position: relative;
  }
  .c-resources__up {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: auto;
  }
  .c-resources__search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .c-resources__clear {
    margin-top: 0;
    margin-left: 2em;
  }
}

@media (min-width: 1024px) {
  .c-resources__search {
    margin-bottom: 60px;
  }
  .c-resources__item {
    display: flex;
    align-items: flex-start;
    margin-left: -177px;
    margin-right: 0;
    width: 100%;
  }
  .c-resources__item-tags {
    display: block;
    width: 108px;
    margin-right: 69px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 5px;
  }
  .c-resources__item-tag {
    padding: 0.4em;
    border: 1px solid #cbcbcc;
    text-align: center;
    line-height: 1.2;
    border-radius: 1.07692em;
    margin-bottom: 1em;
  }
  .c-resources__item-content {
    flex-grow: 1;
  }
}

/* ==========================================================================
   #SHARE
   ========================================================================== */
.c-share {
  display: flex;
}

.c-share__link {
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.c-share__icon {
  display: block;
}

.c-share__icon--linkedin {
  margin-bottom: 3px;
}

/* ==========================================================================
   #SIDE MENU
   ========================================================================== */
@media (min-width: 768px) {
  .c-side-menu {
    left: 0;
    top: 110px;
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0.3em;
  }
  .c-side-menu--margin {
    margin-top: 50px;
  }
  .admin-bar .c-side-menu {
    top: 142px;
  }
  @supports (color: var(--var)) {
    .admin-bar .c-side-menu {
      top: calc(110px + var(--admin-bar));
    }
  }
  .c-side-menu__item {
    display: block;
    max-width: 100%;
  }
  .c-side-menu__item-link {
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-color 0.3s;
  }
  .no-touch .c-side-menu__item-link:hover, .c-side-menu__item-link.is-active, .c-side-menu__item-link:active, .c-side-menu__item-link:focus {
    border-color: #f7aecb;
    color: #01b3ad;
  }
  .c-side-menu__item.is-active .c-side-menu__item-link {
    border-color: #f7aecb;
    color: #01b3ad;
  }
  .c-side-menu__item + .c-side-menu__item {
    margin-top: 0.5em;
  }
}

/* ==========================================================================
   #COLUMNS
   ========================================================================== */
.c-columns {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-left: -15px;
}

.c-columns__column {
  width: calc(50% - 10px);
  margin-left: 10px;
  margin-bottom: 15px;
}

.c-columns--image .c-columns__column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.c-columns__image {
  object-position: left center;
  max-width: 180px;
  max-height: 72px;
  width: 100%;
  height: auto;
}

.c-columns__title {
  margin-bottom: 0;
}

@media (max-width: 1199.98px) {
  .o-block__sidebar {
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .c-columns__image {
    max-width: 200px;
    max-height: 92px;
  }
  .c-columns--image .c-columns__column,
  .c-columns__column {
    width: calc(100% / 3 - 15px);
  }
  .c-columns--four .c-columns__column {
    width: calc(25% - 15px);
  }
}

@media (min-width: 1024px) {
  .c-columns__column {
    padding-right: 30px;
  }
}

/* ==========================================================================
   #CURRENT EVENTS
   ========================================================================== */
.c-current-events {
  margin-bottom: 10px;
}

.c-current-events--subpage {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.c-current-events__title {
  margin-bottom: 0.2em;
}

.c-current-events__month {
  margin-bottom: 30px;
}

.c-current-events__link {
  margin-bottom: 75px;
}

html.ie .c-current-events__link {
  opacity: 0.9999;
}

.c-current-events__items {
  margin: -40px -13px 0;
  max-width: 620px;
}

.c-current-events__item {
  border-top: 1px solid currentColor;
  padding: 40px 13px 40px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.c-current-events__item:first-child {
  border-top: none;
}

.c-current-events__day {
  width: 80px;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-current-events__day-name {
  margin-bottom: 5px;
}

.c-current-events__day-number {
  font-size: 55px;
  line-height: 1;
  margin-bottom: 0;
}

.c-current-events__events {
  margin-top: 35px;
  margin-bottom: 0;
}

.c-current-events__event {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.c-current-events__time {
  width: 75px;
  padding-right: 5px;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-current-events__bg {
  background-color: #fae9e8;
}

@media (min-width: 768px) {
  .c-current-events {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .c-current-events--subpage {
    margin-bottom: 0;
    padding-bottom: 40px;
    padding-top: 200px;
  }
  .c-current-events__items {
    margin-left: auto;
    margin-right: auto;
  }
  .c-current-events__item {
    padding: 40px 74px;
  }
  .c-current-events__day {
    width: 118px;
  }
  .c-current-events__time {
    width: 90px;
  }
}

@media (min-width: 1024px) {
  .c-current-events__items {
    max-width: 1114px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }
  .c-current-events__item {
    width: 44.8833%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .c-current-events__item:nth-child(2) {
    border-top: none;
  }
}

@media (min-width: 1200px) {
  .c-current-events {
    margin-bottom: 100px;
    margin-top: 100px;
  }
  .c-current-events--subpage {
    margin-bottom: 0;
    padding-bottom: 80px;
    padding-top: 100px;
  }
  .c-current-events__link {
    margin-bottom: 110px;
  }
}

/* ==========================================================================
   #HERO
   ========================================================================== */
.c-hero {
  display: flex;
  position: relative;
}

.c-hero--bg {
  margin-bottom: 40px;
}

.c-hero--chevron {
  border-bottom: 30px solid transparent;
  min-height: calc(100vh - 100px);
  position: relative;
  margin-bottom: 20px;
}

html.ie11 .c-hero--chevron {
  align-items: center;
}

html.ie11 .c-hero--chevron:before {
  display: block;
  content: '';
  width: 0;
  height: calc(100vh - 296px);
}

.c-hero--skew {
  margin-bottom: 15px;
}

.c-hero__bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.c-hero__inner {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 80px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}

.c-hero--bg .c-hero__inner {
  padding-bottom: 100px;
}

.c-hero--skew .c-hero__inner {
  padding-bottom: 140px;
  margin-top: 0;
}

.c-hero--chevron .c-hero__inner {
  padding-top: 40px;
  padding-bottom: 40px;
}

.c-hero__chevron {
  text-align: right;
}

.c-hero__chevron-text {
  vertical-align: middle;
  margin-left: 10px;
  transition: color 0.3s;
}

.c-hero__chevron--text .o-btn-oval {
  vertical-align: middle;
}

.no-touch .c-hero__chevron--text:hover .c-hero__chevron-text, .c-hero__chevron--text.is-active .c-hero__chevron-text, .c-hero__chevron--text:active .c-hero__chevron-text, .c-hero__chevron--text:focus .c-hero__chevron-text {
  color: #f7aecb;
}

.c-hero--full-height {
  min-height: calc(100vh - 80px);
}

.admin-bar .c-hero--full-height {
  min-height: calc(100vh - 112px);
}

.admin-bar .c-hero--chevron {
  min-height: calc(100vh - 132px);
}

@supports (color: var(--var)) {
  .c-hero--full-height {
    min-height: calc(var(--initial-height) - 80px);
  }
  .c-hero--chevron {
    min-height: calc(var(--initial-height) - 100px);
  }
}

.c-hero__headline {
  color: #6613c9;
  margin-bottom: 20px;
  max-width: 580px;
}

.c-hero--skew .c-hero__headline {
  margin-bottom: 80px;
}

.c-hero--chevron .c-hero__headline {
  margin-bottom: 0.8em;
  max-width: 600px;
}

.c-hero--chevron .c-hero__caption {
  display: none;
}

.c-hero--chevron .c-hero__chevron {
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -30px;
}

.c-hero__breadcrumbs {
  color: #f7aecb;
  margin-bottom: 40px;
}

.c-hero__breadcrumbs:before {
  content: '';
  width: 50px;
  height: 3px;
  display: inline-block;
  vertical-align: 20%;
  margin-right: 15px;
  background-color: currentColor;
}

.c-hero__author {
  letter-spacing: 1.16px;
  color: #6613c9;
}

@media (min-height: 600px) {
  .c-hero--skew {
    min-height: 600px;
  }
}

@media (min-height: 1180px) {
  .c-hero--chevron {
    min-height: 1080px;
  }
  .admin-bar .c-hero--chevron {
    min-height: 1048px;
  }
  html.ie11 .c-hero--chevron:before {
    height: 784px;
  }
}

@media (max-width: 767.98px) {
  .c-hero--bg .c-hero__breadcrumbs {
    margin-bottom: 70px;
  }
}

@media (min-width: 768px) {
  .c-hero--bg {
    margin-bottom: 50px;
  }
  .c-hero--chevron .c-hero__inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .c-hero--bg .c-hero__inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .c-hero--skew .c-hero__inner {
    padding-bottom: 155px;
  }
  .c-hero--chevron {
    margin-bottom: 40px;
  }
  .c-hero--chevron .c-hero__caption {
    padding-right: 30px;
    display: block;
    position: absolute;
    bottom: 5px;
    right: 0;
    left: 0;
  }
  .c-hero__breadcrumbs {
    margin-bottom: 40px;
  }
  .c-hero__headline {
    margin-bottom: 40px;
  }
  .c-hero--skew .c-hero__headline {
    margin-bottom: 50px;
  }
}

@media (min-width: 1024px) {
  .c-hero--chevron .c-hero__headline {
    max-width: 660px;
  }
}

@media (min-width: 1200px) {
  .c-hero--chevron {
    margin-bottom: 70px;
  }
  .c-hero--chevron .c-hero__caption {
    bottom: 30px;
    padding-right: 15px;
  }
  .c-hero--skew .c-hero__headline {
    font-size: 75px;
    line-height: 94px;
  }
  .c-hero__chevron--text {
    font-size: 18px;
  }
}

/* ==========================================================================
   #INTRO TEXT
   ========================================================================== */
.c-intro-text {
  max-width: 732px;
}

/* ==========================================================================
   #QUOTE
   ========================================================================== */
.c-quotes__item {
  text-align: center;
  margin-bottom: 35px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.c-quotes__inner {
  margin-top: 35px;
}

.c-quotes__content {
  text-align: left;
}

.c-quotes__quote {
  line-height: 28px;
  font-size: 22px;
  margin-bottom: 20px;
}

.c-quotes__link {
  font-size: 14px;
  letter-spacing: 0.9px;
}

.c-quotes__media {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .c-quotes__item {
    text-align: left;
    margin-left: 0;
    max-width: none;
    display: flex;
    align-items: flex-start;
  }
  .c-quotes__media {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 35px;
  }
  .c-quotes__quote {
    line-height: 24px;
    font-size: 18px;
    margin-bottom: 15px;
  }
}

@media (min-width: 1024px) {
  .c-quotes__quote {
    line-height: 28px;
    font-size: 22px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .c-quotes__item:last-child {
    margin-bottom: 0;
  }
}

/* ==========================================================================
   #ROOM
   ========================================================================== */
.c-room {
  margin-bottom: 30px;
}

.c-room .o-block__title + .c-room__subtitle {
  margin-top: -10px;
  margin-bottom: 1em;
}

.c-room__images {
  position: relative;
  margin-bottom: 30px;
}

.c-room__images:before {
  content: '';
  display: block;
  padding-top: 66.74757%;
}

.c-room__image {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  user-select: none;
}

.c-room__image.is-active {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}

.c-room__nav {
  position: absolute;
  top: 50%;
  color: #bfbfbf;
  transition: color 0.3s, background-color 0.3s;
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 40px;
  width: 0.75em;
  height: 1em;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.5em;
}

.no-touch .c-room__nav:hover, .c-room__nav.is-active, .c-room__nav:active, .c-room__nav:focus {
  color: #01b3ad;
}

.c-room__nav-icon {
  width: 0.25em;
  height: 0.4em;
  display: block;
}

.c-room__nav--prev {
  left: 0;
}

.c-room__nav--next {
  right: 0;
}

.c-room__nav--next .c-room__nav-icon {
  transform: rotate(180deg);
}

.c-class__description-column {
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .c-room__images {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@media (min-width: 768px) {
  .c-room__nav {
    font-size: 50px;
  }
  .c-room__description {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
  }
  .c-room__description-column {
    margin-left: 20px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@media (min-width: 1024px) {
  .c-room__nav {
    font-size: 60px;
  }
  .c-room .o-block__title + .c-room__subtitle {
    margin-top: -15px;
  }
  .c-room .o-block__sidebar {
    margin-top: 40px;
  }
}

/* ==========================================================================
   #ROOMS
   ========================================================================== */
.c-rooms__text {
  margin-bottom: 30px;
}

.c-rooms__media {
  margin-bottom: 25px;
  position: relative;
}

.c-rooms__item--small .c-rooms__media {
  padding-top: 66.56716%;
  margin-bottom: 11px;
}

.c-rooms__separator {
  margin-top: 30px;
  margin-bottom: 30px;
}

.c-rooms__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-rooms__item--small .c-rooms__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-rooms__button {
  font-size: 45px;
  width: 1em;
  height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f3f2ef;
  box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.36);
  transition: background-color 0.3s, color 0.3s;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.c-rooms__item--small .c-rooms__button {
  font-size: 35px;
}

.c-rooms__button-icon {
  width: 0.24444em;
  height: 0.24444em;
  display: block;
}

.c-rooms__title {
  margin-bottom: 11px;
}

.c-rooms__item {
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .c-rooms__group {
    white-space: nowrap;
    margin-left: -30px;
    margin-right: -30px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
    margin-bottom: -30px;
  }
  .c-rooms__item--small {
    display: inline-block;
    width: 250px;
    margin-left: 17px;
    white-space: normal;
  }
  .c-rooms__item--small:first-child {
    margin-left: 30px;
  }
  .c-rooms__item--small:last-child {
    margin-right: 30px;
  }
  .c-rooms .o-block__sidebar {
    margin-top: 40px;
  }
}

@media (min-width: 768px) {
  .c-rooms__separator {
    display: none;
  }
  .c-rooms__group {
    display: flex;
    flex-wrap: wrap;
    margin-left: -17px;
  }
  .c-rooms__item--small {
    width: calc(50% - 17px);
    margin-left: 17px;
  }
}

@media (min-width: 768px) {
  .c-rooms__items {
    margin-bottom: -30px;
  }
}

@media (min-width: 1200px) {
  .c-rooms__item--small {
    width: calc(50% - 20px);
    margin-left: 20px;
  }
  .c-rooms__group {
    margin-left: -20px;
  }
  .c-rooms__item--small .c-rooms__button {
    font-size: 45px;
  }
}

/* ==========================================================================
   #SEPARATOR
   ========================================================================== */
.c-separator {
  border: none;
  height: 1px;
  background-color: #e5e5e5;
  margin-left: 0;
  margin-right: auto;
}

.c-separator--transparent {
  background-color: transparent;
}

@media (max-width: 767.98px) {
  .c-separator {
    margin-left: -13px;
    margin-right: -13px;
  }
}

/* ==========================================================================
   #SPACE
   ========================================================================== */
.c-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-space__caption {
  font-size: 10px;
}

.c-space__image {
  display: block;
  margin-bottom: 4px;
  height: auto;
  width: 100%;
}

.c-space__media {
  margin-bottom: 7px;
}

.c-space__heart {
  width: 23px;
  height: 20px;
  vertical-align: -20%;
}

.c-space__from {
  margin-bottom: 20px;
}

.c-space__title {
  margin-bottom: 8px;
  margin-right: 30px;
  max-width: 500px;
}

.c-space__text {
  margin-bottom: 11px;
  margin-right: 30px;
  max-width: 500px;
}

@media (min-width: 768px) {
  .c-space {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .c-space__image {
    margin-bottom: 20px;
  }
  .c-space__caption {
    font-size: 14px;
  }
  .c-space__from {
    margin-bottom: 30px;
  }
  .c-space__title {
    margin-bottom: 13px;
  }
  .c-space__text {
    margin-bottom: 1.4em;
  }
}

@media (min-width: 1024px) {
  .c-space__inner {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
  .c-space__media {
    width: 56.6129%;
    margin-bottom: 41px;
  }
  .c-space__content {
    width: 38.22581%;
    margin-bottom: 0;
  }
  .c-space__text,
  .c-space__title {
    margin-right: 0;
  }
  .c-space__image {
    margin-bottom: 17px;
  }
}

@media (min-width: 1200px) {
  .c-space__title {
    margin-bottom: 0.5em;
  }
  .c-space__text {
    margin-bottom: 1.5em;
  }
}

/* ==========================================================================
   #STAFF
   ========================================================================== */
.c-staff__subtitle {
  margin-bottom: 35px;
}

.c-staff__items {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -20px;
  margin-bottom: -30px;
}

.c-staff__media {
  margin-bottom: 15px;
}

.c-staff__item {
  width: calc(50% - 20px);
  margin-left: 20px;
  max-width: 200px;
  margin-bottom: 30px;
}

.c-staff__name {
  margin-right: -10px;
  display: block;
  margin-bottom: 0;
}

.c-staff .c-staff__name {
  line-height: 1.1;
}

.c-staff__text {
  border-top: 6px solid rgba(247, 174, 203, 0.2);
  margin-top: auto;
  padding-top: 10px;
  min-height: 30px;
}

.c-staff__position {
  margin-top: -0.2em;
  margin-bottom: 8px;
}

@media (max-width: 599.98px) {
  .c-staff .c-staff__name {
    font-size: 16px;
  }
}

@media (max-width: 1199.98px) {
  .c-staff .c-staff__position {
    font-size: 14px;
  }
}

@media (max-width: 1199.98px) {
  .o-block__sidebar {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .c-staff__name {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .c-staff__items {
    margin-left: -30px;
    margin-bottom: -40px;
  }
  .c-staff__item {
    width: calc(100% / 3 - 30px);
    margin-left: 30px;
    margin-bottom: 40px;
  }
  .c-staff__subtitle {
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .c-staff__name {
    font-size: 20px;
  }
  .c-staff .o-block__title {
    margin-bottom: 40px;
  }
}

/* ==========================================================================
   #TESTIMONIALS
   ========================================================================== */
.c-testimonials {
  margin-top: 30px;
  margin-bottom: 20px;
}

.c-testimonials__title {
  margin-bottom: 10px;
}

.c-testimonials__text {
  margin-bottom: 1em;
}

.c-testimonials__item {
  margin-top: 60px;
}

.c-testimonials__bg {
  object-position: 20% top;
}

.c-testimonials__inner {
  padding-top: 10px;
  padding-bottom: 90px;
}

.c-testimonials__author {
  margin-bottom: 0.5em;
}

.c-testimonials__quote {
  margin-bottom: 0.75em;
}

.c-testimonials__links {
  display: flex;
}

.c-testimonials__link {
  margin-bottom: 0;
}

.c-testimonials__link + .c-testimonials__link:before {
  content: '|';
  font-weight: 400;
  color: #fff;
  margin-left: 0.75em;
  margin-right: 0.75em;
}

@media (min-width: 768px) {
  .c-testimonials__inner {
    padding-top: 50px;
    padding-bottom: 200px;
  }
  .c-testimonials__title {
    margin-bottom: 40px;
  }
  .c-testimonials__text {
    margin-bottom: 1.2em;
  }
  .c-testimonials__items {
    margin-top: 140px;
    margin-left: auto;
    margin-right: 0;
    max-width: 650px;
  }
  .c-testimonials__item {
    margin-top: 80px;
    display: flex;
    align-items: flex-start;
  }
  .c-testimonials__image {
    margin-right: 25px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .c-testimonials__quote {
    margin-bottom: 1em;
  }
}

@media (min-width: 1024px) {
  .c-testimonials__items {
    margin-top: 105px;
  }
}

@media (min-width: 1200px) {
  .c-testimonials__inner {
    padding-top: 100px;
    padding-bottom: 300px;
  }
  .c-testimonials__text {
    margin-bottom: 1.6em;
  }
}

@media (min-width: 1320px) {
  .c-testimonials__items {
    margin-left: calc(50% - 79px);
  }
}

@media (min-width: 1400px) {
  .c-testimonials__inner {
    padding-bottom: 420px;
  }
}

/* ==========================================================================
   #TEXT TILES
   ========================================================================== */
.c-text-tiles {
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-text-tiles:last-child {
  margin-bottom: 50px;
}

.c-text-tiles__title {
  margin-bottom: 0.8em;
}

.c-text-tiles__text {
  margin-bottom: 1.5em;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.c-text-tiles__buttons {
  margin: 35px auto auto;
  width: 270px;
  max-width: 100%;
}

.c-text-tiles__button {
  width: 270px;
  max-width: 100%;
  margin-top: 35px;
}

@media (min-width: 700px) {
  .c-text-tiles {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .c-text-tiles__buttons {
    width: 612px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .c-text-tiles__button {
    margin-left: 18px;
    margin-right: 18px;
  }
  .c-text-tiles:last-child {
    margin-bottom: 75px;
  }
}

@media (min-width: 1024px) {
  .c-text-tiles__buttons {
    width: 918px;
  }
  .c-text-tiles:last-child {
    margin-bottom: 100px;
  }
}

@media (min-width: 1200px) {
  .c-text-tiles {
    margin-top: 70px;
    margin-bottom: 20px;
  }
  .c-text-tiles__text {
    margin-bottom: 2.27273em;
  }
}

@media (min-width: 1310px) {
  .c-text-tiles__buttons {
    width: 1224px;
  }
}

/* ==========================================================================
   #TEXT
   ========================================================================== */
@media (min-width: 1200px) {
  .c-text .o-block__text {
    margin-bottom: 0;
  }
}

/* ==========================================================================
   #TWITTER
   ========================================================================== */
.c-twitter {
  background-color: #dbf4f3;
  padding-top: 200px;
  padding-bottom: 220px;
  margin-bottom: -85px;
}

.c-twitter:last-child {
  margin-bottom: 0;
}

.o-block-transparent + .c-twitter {
  margin-top: -45px;
  z-index: -1;
}

.c-twitter__inner {
  max-width: 908px;
}

.c-twitter__logo {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-twitter__handle {
  margin-top: 22px;
}

.c-twitter__cta {
  margin-top: 25px;
}

.c-twitter__items {
  display: none;
}

@media (min-width: 768px) {
  .c-twitter {
    padding-bottom: 260px;
    margin-bottom: -180px;
  }
  .o-block-transparent + .c-twitter {
    margin-top: -85px;
  }
  .c-twitter__items {
    display: flex;
    margin-left: -22px;
    margin-top: 60px;
    margin-bottom: 0;
    align-items: stretch;
  }
  .c-twitter__item {
    margin-left: 22px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% / 3 - 22px);
    flex-shrink: 0;
    flex-grow: 0;
  }
  .c-twitter__text {
    margin-bottom: 1em;
    width: 100%;
  }
  .c-twitter__hr {
    border: none;
    height: 1px;
    background-color: currentColor;
    margin-top: auto;
    margin-bottom: 1em;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .c-twitter {
    margin-bottom: -300px;
    padding-bottom: 320px;
    padding-top: 240px;
  }
  .o-block-transparent + .c-twitter {
    margin-top: -180px;
  }
}

@media (min-width: 1400px) {
  .c-twitter {
    padding-top: 280px;
    padding-bottom: 340px;
    margin-bottom: -340px;
  }
  .o-block-transparent + .c-twitter {
    margin-top: -200px;
  }
}

/* ==========================================================================
   #BLOG POST
   ========================================================================== */
.c-blog-post--featured {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e6e6d9;
  border-top: 1px solid #e6e6d9;
}

.c-blog-post--featured.c-blog-post--first {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 0;
  border-bottom: 0;
}

.c-blog-post__media {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  display: block;
  position: relative;
  margin-bottom: 20px;
  max-width: 400px;
  max-height: 560px;
}

.c-blog-post__media:before {
  content: '';
  display: block;
  padding-top: 121.875%;
}

.c-blog-post--first .c-blog-post__media {
  max-width: none;
  max-height: none;
}

.c-blog-post--first .c-blog-post__media:not([style]):before {
  display: none;
}

.c-blog-post__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-blog-post--first .c-blog-post__media:not([style]) .c-blog-post__image {
  position: static;
  height: auto;
}

.c-blog-post__image-text {
  position: absolute;
  right: 15px;
  bottom: 20px;
  font-size: 15px;
}

.c-blog-post__categories {
  margin-bottom: 15px;
  font-size: 14px;
}

.c-blog-post--first .c-blog-post__categories {
  margin-bottom: 20px;
}

.c-blog-post__title {
  margin-bottom: 6px;
}

.c-blog-post--featured .c-blog-post__title {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-blog-post--featured {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .c-blog-post--first .c-blog-post__categories {
    margin-bottom: 13px;
  }
}

@media (min-width: 1024px) {
  .c-blog-post--first {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .c-blog-post--featured {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .c-blog-post__categories {
    width: 100%;
  }
  .c-blog-post--first .c-blog-post__categories {
    margin-bottom: 20px;
  }
  .c-blog-post--first .c-blog-post__media {
    width: 400px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 35px;
  }
  .c-blog-post--first .c-blog-post__content {
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - 435px);
    max-width: 840px;
  }
  .c-blog-post--first .c-blog-post__content:first-child {
    max-width: 680px;
  }
  .c-blog-post--first .c-blog-post__title {
    margin-top: -0.2em;
  }
  .c-blog-post__title {
    margin-bottom: 10px;
  }
  .c-blog-post--featured .c-blog-post__title {
    margin-bottom: 30px;
  }
}

/* ==========================================================================
   #BLOG
   ========================================================================== */
.c-blog {
  margin-top: 40px;
  margin-bottom: 40px;
}

.c-blog__sidebar {
  display: none;
}

.c-blog__subtitle,
.c-blog__news-date,
.c-blog__trigger,
.c-blog-post__image-text,
.c-blog-post__categories {
  letter-spacing: 2px;
}

.c-blog__subtitle {
  margin-bottom: 0;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-blog-post__author {
  letter-spacing: 0.9px;
  font-size: 14px;
}

.c-blog__content {
  margin-top: 30px;
}

.c-blog__content--featured {
  border-top: 6px solid rgba(247, 174, 203, 0.2);
  padding-top: 40px;
}

.c-blog__trigger {
  font-size: 14px;
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.no-touch .c-blog__trigger:hover .c-blog__trigger-icon, .c-blog__trigger.is-active .c-blog__trigger-icon, .c-blog__trigger:active .c-blog__trigger-icon, .c-blog__trigger:focus .c-blog__trigger-icon {
  fill: #ff80ff;
}

.c-blog__trigger-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  height: 42px;
}

.c-blog__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

.c-blog__header .c-blog__subtitle {
  margin-right: 15px;
}

.c-blog__categories {
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: auto;
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.16);
  max-width: 288px;
}

.no-touch .c-blog__categories:hover .c-blog__categories-list, .c-blog__categories.is-active .c-blog__categories-list, .c-blog__categories:active .c-blog__categories-list, .c-blog__categories:focus .c-blog__categories-list {
  opacity: 1;
  visibility: visible;
}

.c-blog__categories-list {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  transition: opacity 0.3s, visibility 0.3s;
  background-color: #fff;
  margin: 0;
  padding: 10px 18px 18px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  opacity: 0;
  visibility: hidden;
}

.c-blog__categories-item {
  margin-bottom: 0;
}

.c-blog__categories-item + .c-blog__categories-item {
  margin-top: 1em;
}

.c-blog__more {
  margin-top: 40px;
}

.c-blog__items .c-blog-post {
  margin-top: 43px;
}

.c-blog__items {
  margin-top: -23px;
}

@media (max-width: 639.98px) {
  .c-blog .c-blog-post {
    margin-left: -13px;
    margin-right: -13px;
    padding: 0 13px;
  }
}

@media (max-width: 1199.98px) {
  .c-blog__inner {
    max-width: 832px;
  }
}

@media (min-width: 640px) {
  .c-blog {
    margin-bottom: 100px;
  }
  .c-blog__content {
    margin-top: 40px;
  }
  .c-blog__content--featured {
    padding-top: 40px;
    margin-top: 45px;
  }
  .c-blog__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-blog .c-blog-post {
    width: calc(50% - 16px);
    margin-top: 45px;
  }
  .c-blog .c-blog-post--first {
    margin-top: 0;
  }
  .c-blog .c-blog-post--featured {
    width: 100%;
  }
  .c-blog__more {
    margin-top: 70px;
  }
}

@media (min-width: 768px) {
  .c-blog {
    margin-top: 65px;
  }
  .c-blog__title {
    margin-bottom: 60px;
  }
  .c-blog__items {
    margin-top: -45px;
  }
  .c-blog__header {
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .c-blog {
    margin-bottom: 120px;
    margin-top: 140px;
  }
  .c-blog-post__media {
    margin-bottom: 25px;
  }
  .c-blog-post--first .c-blog-post__media {
    margin-bottom: 0;
  }
  .c-blog__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .c-blog__title {
    width: 100%;
  }
  .c-blog .c-blog-post--first {
    width: 100%;
  }
  .c-blog .c-blog-post {
    width: calc(50% - 20px);
  }
  .c-blog .c-blog-post--featured {
    width: 100%;
  }
  .c-blog__content {
    margin-top: 80px;
    padding-top: 60px;
    max-width: 840px;
    width: 67.74194%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-blog__sidebar {
    width: 29.83871%;
    display: block;
    margin-left: auto;
    margin-top: 156px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: -20px;
  }
  .c-blog__news {
    margin-bottom: 80px;
  }
  .c-blog__news-date {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
  }
  .c-blog__news-title {
    margin-bottom: 50px;
  }
  .c-blog__news-post-title {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .c-blog__news-post + .c-blog__news-post {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 6px solid rgba(247, 174, 203, 0.2);
  }
  .c-blog__news-author {
    color: #999ea8;
    font-size: 14px;
  }
  .c-blog__twitter {
    margin-bottom: 80px;
  }
  .c-blog__twitter-icon {
    vertical-align: middle;
    margin-right: 5px;
  }
  .c-blog__twitter-text {
    word-wrap: break-word;
  }
  .c-blog__twitter-title {
    margin-bottom: 30px;
  }
  .c-blog__twitter-date {
    margin-top: 13px;
    padding-top: 13px;
    display: block;
    border-top: 6px solid rgba(247, 174, 203, 0.2);
  }
  .c-blog__twitter-post + .c-blog__twitter-post {
    margin-top: 50px;
  }
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post__content {
  margin-top: 50px;
  margin-bottom: 50px;
}

.c-post__text {
  margin-bottom: 40px;
}

.c-post .c-share {
  margin-bottom: 40px;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
  max-width: 100%;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

@media (min-width: 768px) {
  .c-post__content {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .c-post .c-share {
    margin-bottom: 70px;
  }
  .c-post__text {
    margin-bottom: 50px;
  }
}

@media (min-width: 1200px) {
  .c-post .c-share {
    margin-bottom: 90px;
  }
  .c-post__content {
    margin-bottom: 80px;
  }
}

/* ==========================================================================
   #CALENDAR
   ========================================================================== */
.c-calendar {
  border-radius: 2px;
  background-color: #f3f2ef;
  padding: 30px 0;
  width: 290px;
}

.c-calendar__button-icon {
  width: 7px;
  height: 14px;
  display: block;
  margin: auto;
}

.c-calendar__nav-button--next .c-calendar__button-icon {
  transform: rotate(180deg);
}

.c-calendar__nav-button {
  width: 48px;
  height: 48px;
  opacity: 0.43;
  transition: opacity 0.3s, color 0.3s;
}

.no-touch .c-calendar__nav-button:hover, .c-calendar__nav-button.is-active, .c-calendar__nav-button:active, .c-calendar__nav-button:focus {
  opacity: 1;
}

.c-calendar__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -17px 9px;
}

.c-calendar__head,
.c-calendar__days {
  display: flex;
  justify-content: flex-start;
  width: 210px;
  margin: 0 auto;
}

.c-calendar__head {
  color: #d2d2c9;
  margin-bottom: 3px;
  margin-top: 10px;
}

.c-calendar__days {
  flex-wrap: wrap;
}

.c-calendar__day {
  letter-spacing: -1.27px;
}

.c-calendar__button {
  width: 26px;
  height: 26px;
  margin: 5px 2px;
  border-radius: 50%;
  font-size: 15px;
}

.c-calendar__button:not(button) {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ==========================================================================
   #EVENT
   ========================================================================== */
.c-events__image-top {
  width: 100%;
  display: block;
  height: 110px;
  object-position: 35% center;
}

.c-events__months {
  overflow: hidden;
  height: 100px;
  margin-bottom: 40px;
  font-size: 1px;
}

.c-events__months-slider {
  padding-bottom: 20px;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  height: 120px;
  white-space: nowrap;
}

.c-events__months-inner:before,
.c-events__months-inner:after {
  display: inline-block;
  content: '';
  width: 30px;
}

.c-events__months-inner:after {
  width: calc(100vw - 100px);
}

.c-events__months-month {
  color: #e6e6d9;
  border-top: 6px solid transparent;
  transition: color 0.3s, border-color 0.3s;
  display: inline-block;
  padding: 20px 0;
  margin: 0 10px;
}

.no-touch .c-events__months-month:hover, .c-events__months-month.is-active, .c-events__months-month:active, .c-events__months-month:focus {
  color: #6613c9;
}

.c-events__months-month.is-active {
  border-color: #6613c9;
}

.c-events__items {
  text-align: center;
}

.c-events__item {
  text-align: left;
}

.c-events__item:not(.is-active) {
  display: none;
}

.c-events__item + .c-events__item {
  padding-top: 50px;
  margin-top: 50px;
  border-top: 1px solid #e6e6d9;
}

.c-events__item-media {
  margin-bottom: 25px;
  display: block;
  width: 228px;
  height: 228px;
  position: relative;
}

.c-events__item-media:empty {
  height: 1px;
}

.c-events__item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: block;
  border-radius: 50%;
}

.c-events__item-date {
  margin-bottom: 20px;
  display: block;
}

.c-events__item-text {
  margin-bottom: 20px;
}

.c-events__item-audience {
  color: #7e7e80;
  margin-bottom: 15px;
}

.c-events__content {
  margin-bottom: 80px;
}

.c-events__sidebar {
  background-color: #fafaf9;
  margin-left: -30px;
  margin-right: -30px;
  padding: 80px 0;
}

.c-events__sidebar-items {
  width: 272px;
  margin: 0 auto;
}

.c-events__sidebar-item {
  border-bottom: 1px solid #e6e6d9;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.c-events__sidebar-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.c-events__sidebar-title {
  margin-bottom: 18px;
}

.c-events__sidebar-subtitle {
  margin-bottom: 0;
}

.c-events__sidebar-more {
  margin-top: 20px;
}

.c-events__calendar {
  margin: 0 auto 40px;
}

.c-events__more {
  margin-top: 50px;
}

@media (max-width: 767.98px) {
  .c-events__title {
    display: none;
  }
}

@media (min-width: 768px) {
  .c-events__image-top {
    height: 130px;
  }
  .c-events__months-month {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .c-events__months {
    height: 120px;
    margin-bottom: 50px;
  }
  .c-events__months-slider {
    height: 140px;
  }
  .c-events__months-inner:before {
    width: 140px;
  }
  .c-events__title {
    border-bottom: 1px solid #e6e6d9;
    padding-bottom: 10px;
    margin-bottom: 40px;
  }
  .c-events__item {
    display: flex;
    align-items: flex-start;
  }
  .c-events__item-media {
    margin-bottom: 0;
    margin-right: 30px;
    margin-top: 42px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .c-events__item-content {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .c-events__item-audience {
    margin-bottom: 24px;
  }
  .c-events__sidebar {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -40px;
    margin-right: -40px;
  }
  .c-events__calendar {
    margin: 0 75px 0 auto;
  }
  .c-events__sidebar-items {
    margin: 10px auto 0 0;
  }
  .c-events__sidebar-item:last-child {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .c-events__item-media {
    width: 200px;
    height: 200px;
  }
}

@media (min-width: 1024px) {
  .c-events__months-inner:before {
    width: 225px;
  }
  .c-events__months-inner:after {
    width: calc(100vw - 265px);
  }
}

@media (min-width: 1320px) {
  .c-events__months-inner:before {
    width: calc(50vw - 620px + 165px);
  }
  .c-events__months-inner:after {
    width: calc(50vw - 620px + 650px);
  }
}

@media (min-width: 1200px) {
  .c-events {
    margin-bottom: 150px;
  }
  .c-events__months-month {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 60px;
    margin-right: 60px;
  }
  .c-events__months {
    height: 150px;
    margin-bottom: 80px;
  }
  .c-events__months-slider {
    height: 170px;
  }
  .c-events__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .c-events__sidebar {
    background-color: transparent;
    padding: 80px 0 0 0;
    width: 290px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
  }
  .c-events__content {
    max-width: 680px;
    margin-right: 32px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 0;
  }
  .c-events__calendar {
    margin: 0 0 40px 0;
  }
  .c-events__sidebar-item {
    padding-bottom: 50px;
    margin-bottom: 45px;
  }
  .c-events__more {
    margin-top: 100px;
  }
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #COLOR
   ========================================================================== */
.u-fill-cc {
  fill: currentColor;
}

.u-pink {
  color: #ff80ff;
}

.u-pink-light {
  color: #e1d1eb;
}

.u-pink-lighter {
  color: #f7aecb;
}

.u-purple {
  color: #6613c9;
}

.u-teal {
  color: #01b3ad;
}

.u-teal-lighter {
  color: #81f7f3;
}

.u-white {
  color: #fff;
}

.u-grey {
  color: #aeafb4;
}

.u-grey-dark {
  color: #787777;
}

.u-dark {
  color: #31333d;
}

/* ==========================================================================
   #FIT
   ========================================================================== */
.u-fit-cover {
  object-fit: cover;
}

.u-fit-contain {
  object-fit: contain;
}

.u-position-left {
  object-position: left center;
}

/* ==========================================================================
   #HEADING
   ========================================================================== */
.u-h-hero {
  font-size: 86px;
  letter-spacing: -0.05181em;
  line-height: 1;
  text-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
}

.u-h-headline {
  font-size: 29px;
  line-height: 35px;
}

.u-h-blog-headline {
  font-size: 37px;
  line-height: 46px;
}

.u-h-blog-title {
  font-size: 46px;
  line-height: 50px;
}

.u-h-tag {
  font-size: 35px;
  line-height: 45px;
}

.u-h1 {
  font-size: 39px;
  line-height: 1;
}

.u-h2 {
  font-size: 39px;
}

.u-h3 {
  font-size: 39px;
}

.u-h4 {
  font-size: 39px;
  letter-spacing: -0.03256em;
}

.u-h5 {
  font-size: 30px;
  line-height: 37px;
}

.u-h6 {
  font-size: 20px;
  line-height: 27px;
}

@media (min-width: 375px) {
  .u-h-hero {
    font-size: 94px;
  }
}

@media (min-width: 768px) {
  .u-h-hero {
    font-size: 140px;
  }
  .u-h-headline {
    font-size: 39px;
    line-height: 44px;
  }
  .u-h-blog-headline {
    font-size: 57px;
    line-height: 62px;
  }
  .u-h-blog-title {
    font-size: 75px;
    line-height: 85px;
  }
  .u-h1 {
    font-size: 75px;
    line-height: 85px;
  }
  .u-h2 {
    font-size: 55px;
  }
  .u-h3 {
    font-size: 48px;
  }
  .u-h5 {
    font-size: 30px;
    line-height: 37px;
  }
}

@media (min-width: 1200px) {
  .u-h-headline {
    font-size: 48px;
    line-height: 54px;
  }
  .u-h-blog-headline {
    font-size: 57px;
    line-height: 62px;
  }
  .u-h2 {
    font-size: 75px;
  }
  .u-h4 {
    font-size: 43px;
  }
  .u-h5 {
    font-size: 35px;
    line-height: 45px;
  }
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

/* ==========================================================================
   #MARGIN
   ========================================================================== */
.u-no-last-margin > *:last-child {
  margin-bottom: 0;
}

.u-no-first-margin > *:last-child {
  margin-top: 0;
}

/* ==========================================================================
   #TEXT
   ========================================================================== */
.u-bold {
  font-weight: 700;
}

.u-semi-bold {
  font-weight: 600;
}

.u-regular {
  font-weight: 400;
}

.u-upper {
  text-transform: uppercase;
}

.u-normal {
  font-style: normal;
}

.u-italic {
  font-style: italic;
}

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-center {
  text-align: center;
}

.u-left {
  text-align: left;
}

.u-right {
  text-align: right;
}

.u-text-headline {
  font-size: 18px;
  line-height: 30px;
}

.u-text-bigger {
  font-size: 18px;
  line-height: 24px;
}

.u-text-smaller {
  font-size: 13px;
}

.u-text-small {
  font-size: 14px;
}

.u-text-big {
  font-size: 24px;
  line-height: 33px;
}

.u-text-menu {
  font-size: 40px;
}

.u-text-submenu {
  font-size: 30px;
}

@media (min-width: 768px) {
  .u-text-menu {
    font-size: 48px;
  }
  .u-text-headline {
    font-size: 22px;
    line-height: 32px;
  }
}

@media (min-width: 1200px) {
  .u-text-small {
    font-size: 16px;
  }
}
