/* ==========================================================================
   #OVAL HEADER
   ========================================================================== */

.c-oval-header {
  margin-top: 40px;
  margin-bottom: 30px;
}

.c-oval-header__header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.c-oval-header__image {
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 14px;
}

.c-oval-header__title {
  margin-bottom: 0;
}

@include bp(medium) {
  .c-oval-header {
    margin-top: 50px;
  }

  .c-oval-header__image {
    width: 108px;
    height: 108px;
    margin-bottom: 20px;
  }

  .c-oval-header__header {
    display: block;
    margin-bottom: 30px;
  }
}

@include bp(large) {
  .c-oval-header {
    margin-top: 150px;
  }

  .c-oval-header__header {
    position: relative;
  }

  .c-oval-header__image {
    width: 192px;
    height: 192px;
    position: absolute;
    top: -54px;
    right: 100%;
    margin-right: 35px;
    margin-bottom: 0;
  }
}
