/* ==========================================================================
   #HTML
   ========================================================================== */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 16px;
  line-height: (23/16);
  min-height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

// stylelint-disable
html.is-locked body {
  overflow: hidden;
}
// stylelint-enable

