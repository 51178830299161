/* ==========================================================================
   #EVENT
   ========================================================================== */

.c-events__image-top {
  width: 100%;
  display: block;
  height: 110px;
  object-position: 35% center;
}

.c-events__months {
  overflow: hidden;
  height: 100px;
  margin-bottom: 40px;
  font-size: 1px;
}

.c-events__months-slider {
  padding-bottom: 20px;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  height: 120px;
  white-space: nowrap;
}

.c-events__months-inner:before,
.c-events__months-inner:after {
  display: inline-block;
  content: '';
  width: 30px;
}

.c-events__months-inner:after {
  width: calc(100vw - 100px);
}

.c-events__months-month {
  color: $color-grey-lighter;
  border-top: 6px solid transparent;
  transition: color 0.3s, border-color 0.3s;
  display: inline-block;
  padding: 20px 0;
  margin: 0 10px;

  @include link-hover {
    color: $color-purple;
  }

  &.is-active {
    border-color: $color-purple;
  }
}

.c-events__items {
  text-align: center;
}

.c-events__item {
  text-align: left;

  &:not(.is-active) {
    display: none;
  }
}

.c-events__item + .c-events__item {
  padding-top: 50px;
  margin-top: 50px;
  border-top: 1px solid $color-grey-lighter;
}

.c-events__item-media {
  margin-bottom: 25px;
  display: block;
  width: 228px;
  height: 228px;
  position: relative;
}

.c-events__item-media:empty {
  height: 1px;
}

.c-events__item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: block;
  border-radius: 50%;
}

.c-events__item-date {
  margin-bottom: 20px;
  display: block;
}

.c-events__item-text {
  margin-bottom: 20px;
}

.c-events__item-audience {
  color: #7e7e80;
  margin-bottom: 15px;
}

.c-events__content {
  margin-bottom: 80px;
}

.c-events__sidebar {
  background-color: #fafaf9;
  margin-left: -30px;
  margin-right: -30px;
  padding: 80px 0;
}

.c-events__sidebar-items {
  width: 272px;
  margin: 0 auto;
}

.c-events__sidebar-item {
  border-bottom: 1px solid $color-grey-lighter;
  padding-bottom: 40px;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.c-events__sidebar-title {
  margin-bottom: 18px;
}

.c-events__sidebar-subtitle {
  margin-bottom: 0;
}

.c-events__sidebar-more {
  margin-top: 20px;
}

.c-events__calendar {
  margin: 0 auto 40px;
}

.c-events__more {
  margin-top: 50px;
}

@include bp-down(medium) {
  .c-events__title {
    display: none;
  }
}

@include bp(medium) {
  .c-events__image-top {
    height: 130px;
  }

  .c-events__months-month {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 40px;
    margin-right: 40px;
  }

  .c-events__months {
    height: 120px;
    margin-bottom: 50px;
  }

  .c-events__months-slider {
    height: 140px;
  }

  .c-events__months-inner:before {
    width: 140px;
  }

  .c-events__title {
    border-bottom: 1px solid $color-grey-lighter;
    padding-bottom: 10px;
    margin-bottom: 40px;
  }

  .c-events__item {
    display: flex;
    align-items: flex-start;
  }

  .c-events__item-media {
    margin-bottom: 0;
    margin-right: 30px;
    margin-top: 42px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .c-events__item-content {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .c-events__item-audience {
    margin-bottom: 24px;
  }

  .c-events__sidebar {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -40px;
    margin-right: -40px;
  }

  .c-events__calendar {
    margin: 0 75px 0 auto;
  }

  .c-events__sidebar-items {
    margin: 10px auto 0 0;
  }

  .c-events__sidebar-item:last-child {
    padding-bottom: 0;
  }
}

@include bp-between(xlarge, full) {
  .c-events__item-media {
    width: 200px;
    height: 200px;
  }
}

@include bp(large) {
  .c-events__months-inner:before {
    width: 225px;
  }

  .c-events__months-inner:after {
    width: calc(100vw - #{225 + 40}px);
  }
}

@include bp($content-bp) {
  .c-events__months-inner:before {
    width: calc(50vw - #{$content-width / 2}px + #{225 - 60}px);
  }

  .c-events__months-inner:after {
    width: calc(50vw - #{$content-width / 2}px + 650px);
  }
}

@include bp(xlarge) {
  .c-events {
    margin-bottom: 150px;
  }

  .c-events__months-month {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 60px;
    margin-right: 60px;
  }

  .c-events__months {
    height: 150px;
    margin-bottom: 80px;
  }

  .c-events__months-slider {
    height: 170px;
  }

  .c-events__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .c-events__sidebar {
    background-color: transparent;
    padding: 80px 0 0 0;
    width: 290px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
  }

  .c-events__content {
    max-width: 680px;
    margin-right: 32px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: 0;
  }

  .c-events__calendar {
    margin: 0 0 40px 0;
  }

  .c-events__sidebar-item {
    padding-bottom: 50px;
    margin-bottom: 45px;
  }

  .c-events__more {
    margin-top: 100px;
  }
}
