/* ==========================================================================
   #SEPARATOR
   ========================================================================== */

.c-separator {
  border: none;
  height: 1px;
  background-color: #e5e5e5;
  margin-left: 0;
  margin-right: auto;
}

.c-separator--transparent {
  background-color: transparent;
}

@include bp-down(medium) {
  .c-separator {
    margin-left: -13px;
    margin-right: -13px;
  }
}

