/* ==========================================================================
   #SIDE MENU
   ========================================================================== */

@include bp(medium) {
  .c-side-menu {
    left: 0;
    top: 110px;
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0.3em;
  }

  .c-side-menu--margin {
    margin-top: 50px;
  }

  .admin-bar .c-side-menu {
    top: #{110 + 32}px;
  }

  @supports (color: var(--var)) {
    .admin-bar .c-side-menu {
      top: calc(110px + var(--admin-bar));
    }
  }

  .c-side-menu__item {
    display: block;
    max-width: 100%;
  }

  .c-side-menu__item-link {
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-color 0.3s;

    @include link-hover {
      border-color: $color-pink-lighter;
      color: $color-teal;
    }
  }

  .c-side-menu__item.is-active .c-side-menu__item-link {
    border-color: $color-pink-lighter;
    color: $color-teal;
  }

  .c-side-menu__item + .c-side-menu__item {
    margin-top: 0.5em;
  }
}
