/* ==========================================================================
   #CALENDAR
   ========================================================================== */

.c-calendar {
  border-radius: 2px;
  background-color: #f3f2ef;
  padding: 30px 0;
  width: 290px;
}

.c-calendar__button-icon {
  width: 7px;
  height: 14px;
  display: block;
  margin: auto;
}

.c-calendar__nav-button--next .c-calendar__button-icon {
  transform: rotate(180deg);
}

.c-calendar__nav-button {
  width: 48px;
  height: 48px;
  opacity: 0.43;
  transition: opacity 0.3s, color 0.3s;

  @include link-hover {
    opacity: 1;
  }
}

.c-calendar__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: #{-(48 - 14) / 2}px 9px;
}

.c-calendar__head,
.c-calendar__days {
  display: flex;
  justify-content: flex-start;
  width: 210px;
  margin: 0 auto;
}

.c-calendar__head {
  color: #d2d2c9;
  margin-bottom: 3px;
  margin-top: 10px;
}

.c-calendar__days {
  flex-wrap: wrap;
}

.c-calendar__day {
  letter-spacing: -1.27px;
}

.c-calendar__button {
  width: 26px;
  height: 26px;
  margin: 5px 2px;
  border-radius: 50%;
  font-size: 15px;
}

.c-calendar__button:not(button) {
  display: flex;
  justify-content: center;
  align-items: center;
}
