/* ==========================================================================
   #CONTENT
   ========================================================================== */

@include bp-down(medium) {
  .c-content__menu {
    display: none;
  }
}

@include bp(medium) {
  .c-content {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  }

  .c-content__menu {
    width: 190px;
    margin-right: 15px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .c-content__content {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 205px);
    max-width: 960px;
  }
}

@include bp(xlarge) {
  .c-content__menu {
    width: 210px;
  }

  .c-content__content:first-child {
    margin-left: 225px;
  }
}

