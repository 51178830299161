/* ==========================================================================
   #BLOG
   ========================================================================== */

.c-blog {
  margin-top: 40px;
  margin-bottom: 40px;
}

.c-blog__sidebar {
  display: none;
}

.c-blog__subtitle,
.c-blog__news-date,
.c-blog__trigger,
.c-blog-post__image-text,
.c-blog-post__categories {
  letter-spacing: 2px;
}

.c-blog__subtitle {
  margin-bottom: 0;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-blog-post__author {
  letter-spacing: 0.9px;
  font-size: 14px;
}

.c-blog__content {
  margin-top: 30px;
}

.c-blog__content--featured {
  border-top: 6px solid rgba(#f7aecb, 0.2);
  padding-top: 40px;
}

.c-blog__trigger {
  font-size: 14px;
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: $color-white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @include link-hover() {
    .c-blog__trigger-icon {
      fill: $color-pink;
    }
  }
}

.c-blog__trigger-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  height: 42px;
}

.c-blog__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

.c-blog__header .c-blog__subtitle {
  margin-right: 15px;
}

.c-blog__categories {
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: auto;
  position: relative;
  z-index: 1;
  background-color: $color-white;
  border-radius: 3px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.16);
  max-width: 288px;

  @include link-hover() {
    .c-blog__categories-list {
      opacity: 1;
      visibility: visible;
    }
  }
}

.c-blog__categories-list {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  transition: opacity 0.3s, visibility 0.3s;
  background-color: $color-white;
  margin: 0;
  padding: 10px 18px 18px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  opacity: 0;
  visibility: hidden;
}

.c-blog__categories-item {
  margin-bottom: 0;
}

.c-blog__categories-item + .c-blog__categories-item {
  margin-top: 1em;
}

.c-blog__more {
  margin-top: 40px;
}

.c-blog__items .c-blog-post {
  margin-top: 43px;
}

.c-blog__items {
  margin-top: -23px;
}

@include bp-down(640px) {
  .c-blog .c-blog-post {
    margin-left: -13px;
    margin-right: -13px;
    padding: 0 13px;
  }
}

@include bp-down(xlarge) {
  .c-blog__inner {
    max-width: 832px;
  }
}

@include bp(640px) {
  .c-blog {
    margin-bottom: 100px;
  }

  .c-blog__content {
    margin-top: 40px;
  }

  .c-blog__content--featured {
    padding-top: 40px;
    margin-top: 45px;
  }

  .c-blog__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-blog .c-blog-post {
    width: calc(50% - 16px);
    margin-top: 45px;
  }

  .c-blog .c-blog-post--first {
    margin-top: 0;
  }

  .c-blog .c-blog-post--featured {
    width: 100%;
  }

  .c-blog__more {
    margin-top: 70px;
  }
}

@include bp(medium) {
  .c-blog {
    margin-top: 65px;
  }

  .c-blog__title {
    margin-bottom: 60px;
  }

  .c-blog__items {
    margin-top: -45px;
  }

  .c-blog__header {
    margin-bottom: 30px;
  }
}

@include bp(xlarge) {
  .c-blog {
    margin-bottom: 120px;
    margin-top: 140px;
  }

  .c-blog-post__media {
    margin-bottom: 25px;
  }

  .c-blog-post--first .c-blog-post__media {
    margin-bottom: 0;
  }

  .c-blog__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-blog__title {
    width: 100%;
  }

  .c-blog .c-blog-post--first {
    width: 100%;
  }

  .c-blog .c-blog-post {
    width: calc(50% - 20px);
  }

  .c-blog .c-blog-post--featured {
    width: 100%;
  }

  .c-blog__content {
    margin-top: 80px;
    padding-top: 60px;
    max-width: 840px;
    width: 840/$content-width * 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .c-blog__sidebar {
    width: 370/$content-width * 100%;
    display: block;
    margin-left: auto;
    margin-top: 156px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: -20px;
  }

  .c-blog__news {
    margin-bottom: 80px;
  }

  .c-blog__news-date {
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
  }

  .c-blog__news-title {
    margin-bottom: 50px;
  }

  .c-blog__news-post-title {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .c-blog__news-post + .c-blog__news-post {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 6px solid rgba(#f7aecb, 0.2);
  }

  .c-blog__news-author {
    color: #999ea8;
    font-size: 14px;
  }

  .c-blog__twitter {
    margin-bottom: 80px;
  }

  .c-blog__twitter-icon {
    vertical-align: middle;
    margin-right: 5px;
  }

  .c-blog__twitter-text {
    word-wrap: break-word;
  }

  .c-blog__twitter-title {
    margin-bottom: 30px;
  }

  .c-blog__twitter-date {
    margin-top: 13px;
    padding-top: 13px;
    display: block;
    border-top: 6px solid rgba(#f7aecb, 0.2);
  }

  .c-blog__twitter-post + .c-blog__twitter-post {
    margin-top: 50px;
  }
}
