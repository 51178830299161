/* ==========================================================================
   #SEARCH
   ========================================================================== */

.o-search {
  background-color: #f1eafa;
  align-items: stretch;
  position: relative;
  border-radius: 35px;
}

.o-search__input {
  height: 70px;
  padding: 0 24px;
  flex-grow: 1;
  flex-shrink: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 70px);
}

.o-search__submit {
  height: 70px;
  width: 70px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: auto;
  display: block;
}
