/* ==========================================================================
   #CIRCLE BUTTON
   ========================================================================== */

.o-circle-button {
  display: block;
  width: 106px;
  height: 106px;
  position: relative;

  @include link-hover() {
    img {
      transform: scale(1.1);
    }
  }

  img {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0 2px 10px rgba($color-black, 0.25);
    border-radius: 50%;
  }
}

.o-circle-button--nolink {
  @include link-hover() {
    img {
      transform: none;
    }
  }
}

.o-circle-button--big {
  width: 157px;
  height: 157px;
}

@include bp(medium) {
  .o-circle-button {
    width: 157px;
    height: 157px;
  }
}

@include bp-between(medium, large) {
  .o-circle-button--small {
    width: 126px;
    height: 126px;
  }
}
