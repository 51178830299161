///* ========================================================================
//   #LINK
//   ======================================================================== */

@mixin link-hover() {
  @at-root .no-touch &:hover,
  &.is-active,
  &:active,
  &:focus {
    @content;
  }
}

@mixin link($color, $color-hover, $link-parent: '.o-link-parent') {
  color: $color;
  transition: color 0.3s;

  @at-root {
    .no-touch #{$link-parent}:hover &,
    #{$link-parent}:active,
    #{$link-parent}:focus,
    .no-touch &:hover,
    &.is-active,
    &:active,
    &:focus {
      color: $color-hover;
    }
  }
}
