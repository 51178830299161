/* ==========================================================================
   #BUTTON OVAL
   ========================================================================== */

.o-btn-oval {
  width: 60px;
  height: 60px;
  font-size: 30px;
  box-shadow: 0 (2/30) + em (4/30) + em rgba($color-black, 0.1);
  border-radius: 50%;
  background-color: $color-bg;
}

.o-btn-oval:not(input),
.o-btn-oval:not(button) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}


.o-btn-oval__icon {
  display: block;
  width: 1em;
  height: 1em;
  margin: auto;
}

// stylelint-disable-next-line
html.ie11 .o-btn-oval__icon {
  margin: 0;
}

@include bp-down(xlarge) {
  .o-btn-oval--small {
    width: 32px;
    height: 32px;
    font-size: 20px;
  }

  .o-btn-oval--small .o-btn-oval__icon {
    margin-top: 4px;
  }
}
