/* ==========================================================================
   #ROOM
   ========================================================================== */

.c-room {
  margin-bottom: 30px;
}

.c-room .o-block__title + .c-room__subtitle {
  margin-top: -10px;
  margin-bottom: 1em;
}

.c-room__images {
  position: relative;
  margin-bottom: 30px;

  &:before {
    content: '';
    display: block;
    padding-top: (275/412) * 100%;
  }
}

.c-room__image {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  user-select: none;
}

.c-room__image.is-active {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}

.c-room__nav {
  position: absolute;
  top: 50%;
  color: #bfbfbf;
  transition: color 0.3s, background-color 0.3s;
  background-color: rgba($color-white, 0.7);
  font-size: 40px;
  width: (30/40) + em;
  height: 1em;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.5em;

  @include link-hover() {
    color: $color-teal;
  }
}

.c-room__nav-icon {
  width: (10/40) + em;
  height: (16/40) + em;
  display: block;
}

.c-room__nav--prev {
  left: 0;
}

.c-room__nav--next {
  right: 0;
}

.c-room__nav--next .c-room__nav-icon {
  transform: rotate(180deg);
}

.c-class__description-column {
  margin-bottom: 30px;
}

@include bp-down(medium) {
  .c-room__images {
    margin-left: -30px;
    margin-right: -30px;
  }
}

@include bp(medium) {
  .c-room__nav {
    font-size: 50px;
  }

  .c-room__description {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
  }

  .c-room__description-column {
    margin-left: 20px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@include bp(large) {
  .c-room__nav {
    font-size: 60px;
  }

  .c-room .o-block__title + .c-room__subtitle {
    margin-top: -15px;
  }

  .c-room .o-block__sidebar {
    margin-top: 40px;
  }
}
