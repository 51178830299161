/* ==========================================================================
   #STAFF
   ========================================================================== */

.c-staff__subtitle {
  margin-bottom: 35px;
}

.c-staff__items {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -20px;
  margin-bottom: -30px;
}

.c-staff__media {
  margin-bottom: 15px;
}

.c-staff__item {
  width: calc(50% - 20px);
  margin-left: 20px;
  max-width: 200px;
  margin-bottom: 30px;
}

.c-staff__name {
  margin-right: -10px;
  display: block;
  margin-bottom: 0;
}

.c-staff .c-staff__name {
  line-height: 1.1;
}

.c-staff__text {
  border-top: 6px solid rgba($color-pink-lighter, 0.2);
  margin-top: auto;
  padding-top: 10px;
  min-height: 30px;
}

.c-staff__position {
  margin-top: -0.2em;
  margin-bottom: 8px;
}

@include bp-down(600px) {
  .c-staff .c-staff__name {
    font-size: 16px;
  }
}

@include bp-down(xlarge) {
  .c-staff .c-staff__position {
    font-size: 14px;
  }
}

@include bp-down(xlarge) {
  .o-block__sidebar {
    margin-top: 30px;
  }
}

@include bp(medium) {
  .c-staff__name {
    margin-bottom: 5px;
    font-size: 18px;
  }

  .c-staff__items {
    margin-left: -30px;
    margin-bottom: -40px;
  }

  .c-staff__item {
    width: calc(100% / 3 - 30px);
    margin-left: 30px;
    margin-bottom: 40px;
  }

  .c-staff__subtitle {
    margin-bottom: 40px;
  }
}

@include bp(xlarge) {
  .c-staff__name {
    font-size: 20px;
  }

  .c-staff .o-block__title {
    margin-bottom: 40px;
  }
}
