/* ==========================================================================
   #BUTTON PILL
   ========================================================================== */

.o-btn-pill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 99px;
  font-size: 13px;
  line-height: 1;
  padding: (10/13) + em 1em;
  border-radius: (17/13) + em;
}

.o-btn-pill--big {
  font-size: 16px;
  min-width: 120px;
  border-radius: (20/16) + em;
}

@include bp(xlarge) {
  .o-btn-pill {
    font-size: 15px;
    padding: (12.5/15) + em (17/15)+em;
    border-radius: (20/15) + em;
  }

  .o-btn-pill--big {
    font-size: 16px;
  }
}
