/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  width: calc(100% - 60px);
  margin-right: auto;
  margin-left: auto;
  max-width: #{$content-width}px;
}

@include bp(medium) {
  .o-wrapper {
    width: calc(100% - 80px);
  }
}

@include bp(large) {
  .o-wrapper-padding {
    margin-left: 225px;
    margin-right: 69px;
  }
}
