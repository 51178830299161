/* ==========================================================================
   #HERO
   ========================================================================== */

.c-hero {
  display: flex;
  position: relative;
}

.c-hero--bg {
  margin-bottom: 40px;
}

.c-hero--chevron {
  border-bottom: 30px solid transparent;
  min-height: calc(100vh - 100px);
  position: relative;
  margin-bottom: 20px;
}

// stylelint-disable-next-line
html.ie11 .c-hero--chevron {
  align-items: center;

  &:before {
    display: block;
    content: '';
    width: 0;
    height: calc(100vh - #{100 + 166 + 30}px);
  }
}

.c-hero--skew {
  margin-bottom: 15px;
}

.c-hero__bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.c-hero__inner {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 80px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}

.c-hero--bg .c-hero__inner {
  padding-bottom: 100px;
}

.c-hero--skew .c-hero__inner {
  padding-bottom: 140px;
  margin-top: 0;
}

.c-hero--chevron .c-hero__inner {
  padding-top: 40px;
  padding-bottom: 40px;
}

.c-hero__chevron {
  text-align: right;
}

.c-hero__chevron-text {
  vertical-align: middle;
  margin-left: 10px;
  transition: color 0.3s;
}

.c-hero__chevron--text .o-btn-oval {
  vertical-align: middle;
}

.c-hero__chevron--text {
  @include link-hover() {
    .c-hero__chevron-text {
      color: $color-pink-lighter;
    }
  }
}

.c-hero--full-height {
  min-height: calc(100vh - #{$menu-height});
}

.admin-bar .c-hero--full-height {
  min-height: calc(100vh - 112px);
}

.admin-bar .c-hero--chevron {
  min-height: calc(100vh - 132px);
}

@supports (color: var(--var)) {
  .c-hero--full-height {
    min-height: calc(var(--initial-height) - #{$menu-height});
  }

  .c-hero--chevron {
    min-height: calc(var(--initial-height) - 100px);
  }
}

.c-hero__headline {
  color: $color-purple;
  margin-bottom: 20px;
  max-width: 580px;
}

.c-hero--skew .c-hero__headline {
  margin-bottom: $menu-height;
}

.c-hero--chevron .c-hero__headline {
  margin-bottom: 0.8em;
  max-width: 600px;
}

.c-hero--chevron .c-hero__caption {
  display: none;
}

.c-hero--chevron .c-hero__chevron {
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -30px;
}

.c-hero__breadcrumbs {
  color: $color-pink-lighter;
  margin-bottom: 40px;

  &:before {
    content: '';
    width: 50px;
    height: 3px;
    display: inline-block;
    vertical-align: 20%;
    margin-right: 15px;
    background-color: currentColor;
  }
}

.c-hero__author {
  letter-spacing: 1.16px;
  color: $color-purple;
}

@media (min-height: 600px) {
  .c-hero--skew {
    min-height: 600px;
  }
}

@media (min-height: 1180px) {
  .c-hero--chevron {
    min-height: 1080px;
  }

  .admin-bar .c-hero--chevron {
    min-height: 1048px;
  }

  // stylelint-disable-next-line
  html.ie11 .c-hero--chevron:before {
    height: #{1080 - (100 + 166 + 30)}px;
  }
}

@include bp-down(medium) {
  .c-hero--bg .c-hero__breadcrumbs {
    margin-bottom: 70px;
  }
}

@include bp(medium) {
  .c-hero--bg {
    margin-bottom: 50px;
  }

  .c-hero--chevron .c-hero__inner {
    padding-top: $menu-height;
    padding-bottom: $menu-height;
  }

  .c-hero--bg .c-hero__inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .c-hero--skew .c-hero__inner {
    padding-bottom: 155px;
  }

  .c-hero--chevron {
    margin-bottom: 40px;
  }

  .c-hero--chevron .c-hero__caption {
    padding-right: 30px;
    display: block;
    position: absolute;
    bottom: 5px;
    right: 0;
    left: 0;
  }

  .c-hero__breadcrumbs {
    margin-bottom: 40px;
  }

  .c-hero__headline {
    margin-bottom: 40px;
  }

  .c-hero--skew .c-hero__headline {
    margin-bottom: 50px;
  }
}

@include bp(large) {
  .c-hero--chevron .c-hero__headline {
    max-width: 660px;
  }
}

@include bp(xlarge) {
  .c-hero--chevron {
    margin-bottom: 70px;
  }

  .c-hero--chevron .c-hero__caption {
    bottom: 30px;
    padding-right: 15px;
  }

  .c-hero--skew .c-hero__headline {
    font-size: 75px;
    line-height: 94px;
  }

  .c-hero__chevron--text {
    font-size: 18px;
  }
}
