/* ==========================================================================
   #BLOG POST
   ========================================================================== */

.c-blog-post--featured {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $color-grey-lighter;
  border-top: 1px solid $color-grey-lighter;
}

.c-blog-post--featured.c-blog-post--first {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 0;
  border-bottom: 0;
}

.c-blog-post__media {
  box-shadow: 0 2px 4px rgba($color-black, 0.12);
  display: block;
  position: relative;
  margin-bottom: 20px;
  max-width: 400px;
  max-height: 560px;

  &:before {
    content: '';
    display: block;
    padding-top: (390/320) * 100%;
  }
}

.c-blog-post--first .c-blog-post__media {
  max-width: none;
  max-height: none;
}

.c-blog-post--first .c-blog-post__media:not([style]):before {
  display: none;
}

.c-blog-post__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-blog-post--first .c-blog-post__media:not([style]) .c-blog-post__image {
  position: static;
  height: auto;
}

.c-blog-post__image-text {
  position: absolute;
  right: 15px;
  bottom: 20px;
  font-size: 15px;
}

.c-blog-post__categories {
  margin-bottom: 15px;
  font-size: 14px;
}

.c-blog-post--first .c-blog-post__categories {
  margin-bottom: 20px;
}

.c-blog-post__title {
  margin-bottom: 6px;
}

.c-blog-post--featured .c-blog-post__title {
  margin-bottom: 20px;
}

@include bp(medium) {
  .c-blog-post--featured {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .c-blog-post--first .c-blog-post__categories {
    margin-bottom: 13px;
  }
}

@include bp(large) {
  .c-blog-post--first {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .c-blog-post--featured {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .c-blog-post__categories {
    width: 100%;
  }

  .c-blog-post--first .c-blog-post__categories {
    margin-bottom: 20px;
  }

  .c-blog-post--first .c-blog-post__media {
    width: 400px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 35px;
  }

  .c-blog-post--first .c-blog-post__content {
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - 435px);
    max-width: 840px;
  }

  .c-blog-post--first .c-blog-post__content:first-child {
    max-width: 680px;
  }

  .c-blog-post--first .c-blog-post__title {
    margin-top: -0.2em;
  }

  .c-blog-post__title {
    margin-bottom: 10px;
  }

  .c-blog-post--featured .c-blog-post__title {
    margin-bottom: 30px;
  }
}
