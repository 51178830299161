/* ==========================================================================
   #FIT
   ========================================================================== */

.u-fit-cover {
  object-fit: cover;
}

.u-fit-contain {
  object-fit: contain;
}

.u-position-left {
  object-position: left center;
}
