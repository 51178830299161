/* ==========================================================================
   #PROGRAMS
   ========================================================================== */

.c-programs {
  margin-bottom: 50px;
}

.c-programs__items {
  position: relative;
  max-width: 680px;
}

.c-programs__up {
  display: none;
}

.c-programs__item {
  border-bottom: 1px solid $color-grey-lighter;
  margin-bottom: 27px;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.c-programs__title {
  margin-bottom: 3px;
}

.c-programs__text {
  margin-bottom: 17px;
}

.c-programs__media {
  position: relative;
  display: block;
}

.c-programs__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-programs__media[style] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-programs__bottom {
  height: 46px;
  text-align: right;
  padding-top: 10px;
}

.c-programs__button {
  margin-bottom: 40px;
  min-width: 187px;
  white-space: nowrap;
}

.c-programs__item:last-of-type .c-programs__button {
  margin-bottom: 0;
}

@include bp(medium) {
  .c-programs__items {
    width: calc(100% - 210px);
  }

  .c-programs__item {
    position: relative;
    margin-bottom: 34px;
  }

  .c-programs__title {
    margin-bottom: 13px;
  }

  .c-programs__footer {
    position: relative;
  }

  .c-programs__button {
    position: absolute;
    left: calc(100% + 20px);
    top: 0;
    margin-bottom: 0;
  }

  .c-programs__text {
    margin-bottom: 27px;
  }

  .c-programs__bottom {
    padding-top: 18px;
    height: auto;
    padding-bottom: 22px;
  }

  .c-programs__transcript.c-programs__transcript {
    font-size: 16px;
  }
}

@include bp(large) {
  .c-programs {
    margin-bottom: 120px;
  }

  .c-programs__items {
    margin-top: 70px;
    position: relative;
  }

  .c-programs__button {
    min-width: 240px;
  }

  .c-programs__up {
    display: block;
    position: absolute;
    left: calc(100% + 20px);
    bottom: 62px;
    min-width: 0;
    white-space: nowrap;
  }
}


@include bp(xlarge) {
  .c-programs__items {
    width: calc(100% - 222px);
  }

  .c-programs__button {
    left: calc(100% + 32px);
  }

  .c-programs__up {
    left: calc(100% + 32px);
  }
}
