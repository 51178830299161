/* ==========================================================================
   #TWITTER
   ========================================================================== */

.c-twitter {
  background-color: #dbf4f3;
  padding-top: 200px;
  padding-bottom: 220px;
  margin-bottom: -85px;
}

.c-twitter:last-child {
  margin-bottom: 0;
}

.o-block-transparent + .c-twitter {
  margin-top: -45px;
  z-index: -1;
}

.c-twitter__inner {
  max-width: 908px;
}

.c-twitter__logo {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-twitter__handle {
  margin-top: 22px;
}

.c-twitter__cta {
  margin-top: 25px;
}

.c-twitter__items {
  display: none;
}

@include bp(medium) {
  .c-twitter {
    padding-bottom: 260px;
    margin-bottom: -180px;
  }

  .o-block-transparent + .c-twitter {
    margin-top: -85px;
  }

  .c-twitter__items {
    display: flex;
    margin-left: -22px;
    margin-top: 60px;
    margin-bottom: 0;
    align-items: stretch;
  }

  .c-twitter__item {
    margin-left: 22px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% / 3 - 22px);
    flex-shrink: 0;
    flex-grow: 0;
  }

  .c-twitter__text {
    margin-bottom: 1em;
    width: 100%;
  }

  .c-twitter__hr {
    border: none;
    height: 1px;
    background-color: currentColor;
    margin-top: auto;
    margin-bottom: 1em;
    width: 100%;
  }
}

@include bp(xlarge) {
  .c-twitter {
    margin-bottom: -300px;
    padding-bottom: 320px;
    padding-top: 240px;
  }

  .o-block-transparent + .c-twitter {
    margin-top: -180px;
  }
}

@include bp(full) {
  .c-twitter {
    padding-top: 280px;
    padding-bottom: 340px;
    margin-bottom: -340px;
  }

  .o-block-transparent + .c-twitter {
    margin-top: -200px;
  }
}
