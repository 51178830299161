/* ==========================================================================
   #TEXT TILES
   ========================================================================== */

.c-text-tiles {
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-text-tiles:last-child {
  margin-bottom: 50px;
}

.c-text-tiles__title {
  margin-bottom: 0.8em;
}

.c-text-tiles__text {
  margin-bottom: 1.5em;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.c-text-tiles__buttons {
  margin: 35px auto auto;
  width: 270px;
  max-width: 100%;
}

.c-text-tiles__button {
  width: 270px;
  max-width: 100%;
  margin-top: 35px;
}


@include bp(700px) {
  .c-text-tiles {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .c-text-tiles__buttons {
    width: 612px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .c-text-tiles__button {
    margin-left: 18px;
    margin-right: 18px;
  }

  .c-text-tiles:last-child {
    margin-bottom: 75px;
  }
}

@include bp(large) {
  .c-text-tiles__buttons {
    width: 3 * 306 + px;
  }

  .c-text-tiles:last-child {
    margin-bottom: 100px;
  }
}

@include bp(xlarge) {
  .c-text-tiles {
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .c-text-tiles__text {
    margin-bottom: (50/22) + em;
  }
}

@include bp(1310px) {
  .c-text-tiles__buttons {
    width: 4 * 306 + px;
  }
}
