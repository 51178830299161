/* ==========================================================================
   #SPACE
   ========================================================================== */

.c-space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-space__caption {
  font-size: 10px;
}

.c-space__image {
  display: block;
  margin-bottom: 4px;
  height: auto;
  width: 100%;
}

.c-space__media {
  margin-bottom: 7px;
}

.c-space__heart {
  width: 23px;
  height: 20px;
  vertical-align: -20%;
}

.c-space__from {
  margin-bottom: 20px;
}

.c-space__title {
  margin-bottom: 8px;
  margin-right: 30px;
  max-width: 500px;
}

.c-space__text {
  margin-bottom: 11px;
  margin-right: 30px;
  max-width: 500px;
}

@include bp(medium) {
  .c-space {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .c-space__image {
    margin-bottom: 20px;
  }

  .c-space__caption {
    font-size: 14px;
  }

  .c-space__from {
    margin-bottom: 30px;
  }

  .c-space__title {
    margin-bottom: 13px;
  }

  .c-space__text {
    margin-bottom: 1.4em;
  }
}

@include bp(large) {
  .c-space__inner {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }

  .c-space__media {
    width: 702 / $content-width * 100%;
    margin-bottom: (77 - 19 - 17) + px;
  }

  .c-space__content {
    width: 474 / $content-width * 100%;
    margin-bottom: 0;
  }

  .c-space__text,
  .c-space__title {
    margin-right: 0;
  }

  .c-space__image {
    margin-bottom: 17px;
  }
}

@include bp(xlarge) {
  .c-space__title {
    margin-bottom: 0.5em;
  }

  .c-space__text {
    margin-bottom: 1.5em;
  }
}
