/* ==========================================================================
   #TEXT
   ========================================================================== */

.u-bold {
  font-weight: $font-bold;
}

.u-semi-bold {
  font-weight: $font-semi-bold;
}

.u-regular {
  font-weight: $font-regular;
}

.u-upper {
  text-transform: uppercase;
}

.u-normal {
  font-style: normal;
}

.u-italic {
  font-style: italic;
}

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-center {
  text-align: center;
}

.u-left {
  text-align: left;
}

.u-right {
  text-align: right;
}

.u-text-headline {
  font-size: 18px;
  line-height: 30px;
}

.u-text-bigger {
  font-size: 18px;
  line-height: 24px;
}

.u-text-smaller {
  font-size: 13px;
}

.u-text-small {
  font-size: 14px;
}

.u-text-big {
  font-size: 24px;
  line-height: 33px;
}

.u-text-menu {
  font-size: 40px;
}

.u-text-submenu {
  font-size: 30px;
}

@include bp(medium) {
  .u-text-menu {
    font-size: 48px;
  }

  .u-text-headline {
    font-size: 22px;
    line-height: 32px;
  }
}

@include bp(xlarge) {
  .u-text-small {
    font-size: 16px;
  }
}
