/* ==========================================================================
   #ANCHOR
   ========================================================================== */

.o-anchor {
  margin-bottom: 15px;
  line-height: 1;
  position: relative;
}

.o-anchor__target {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  top: -126px;
  visibility: hidden;
  pointer-events: none;
}

.o-anchor.is-hidden {
  margin-bottom: 0;
}

@include bp(medium) {
  .o-anchor {
    margin-bottom: 20px;
  }
}
