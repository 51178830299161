/* ==========================================================================
   #LINKS
   ========================================================================== */

.o-link-pink {
  @include link($color-pink, $color-teal-lighter);
}

.o-link-pink-light {
  @include link($color-pink-light, $color-teal);
}

.o-link-pink-lighter {
  @include link($color-pink-lighter, $color-white);
}

.o-link-pink-lighter-pink {
  @include link($color-pink-lighter, $color-pink);
}

.o-link-pink-lighter-teal-lighter {
  @include link($color-pink-lighter, $color-teal-lighter);
}

.o-link-purple {
  @include link($color-purple, $color-teal);
}

.o-link-purple-white {
  @include link($color-purple, $color-white);
}

.o-link-teal {
  @include link($color-teal, $color-purple);
}

.o-link-teal-white {
  @include link($color-teal, $color-white);
}

.o-link-teal-lighten {
  @include link($color-teal, $color-teal-lighter);
}

.o-link-white {
  @include link($color-white, $color-pink);
}

.o-link-white-pink-lighter {
  @include link($color-white, $color-pink-lighter);
}

.o-link-grey {
  @include link($color-grey, $color-teal);
}

.o-link-black {
  @include link($color-black, $color-purple);
}

.o-link-calendar {
  color: $color-purple;
  background-color: $color-pink-light;
  font-weight: $font-bold;
  transition: color 0.3s, background-color 0.3s;
  font-size: 15px;
  line-height: 20px;
  padding: 8px 15px;
  border-radius: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include link-hover() {
    color: $color-white;
    background-color: $color-teal;
  }
}

.o-link-calendar__icon {
  width: 16px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  display: block;
  margin-right: 10px;
  margin-left: 3px;
}

.o-link-pill {
  transition: color 0.3s, background-color 0.3s;
  background-color: #ccf0ef;

  @include link-hover() {
    background-color: $color-purple;
  }
}

.o-link-pill-purple {
  transition: color 0.3s, background-color 0.3s;
  background-color: $color-purple;

  @include link-hover() {
    background-color: $color-teal;
  }
}

.o-link-cta {
  display: inline-block;
  font-weight: 700;
  line-height: (19/16);
  background: $color-teal;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.385049);
  border-radius: 3px;
  transition: color 0.3s, background-color 0.3s;
  color: $color-white;
  padding: 0.8em 1em 0.7em;

  @include link-hover() {
    background-color: $color-purple;
  }
}

.o-link-default-purple {
  a:not([class]) {
    @extend .o-link-purple;
  }
}

.o-link-default-teal {
  a:not([class]) {
    @extend .o-link-teal;
  }
}
