/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  height: $menu-height;
}

.c-header__wrapper {
  position: fixed;
  background-color: $color-bg;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 15;
  box-shadow: 0 2px 4px rgba($color-black, 0.1);
}

.admin-bar .c-header__wrapper {
  top: 32px;
}

// stylelint-disable-next-line
html.is-locked.ie11 .c-header__wrapper {
  padding-right: 17px;
}

@supports (color: var(--var)) {
  // stylelint-disable-next-line
  html.is-locked .c-header__wrapper {
    padding-right: var(--scroll-width);
  }

  .admin-bar .c-header__wrapper {
    top: var(--admin-bar);
  }
}

.c-header__inner {
  height: $menu-height;
  display: flex;
  align-items: center;
  justify-items: flex-start;
}

.c-header__hamburger {
  width: 41px;
  height: 41px;
  margin-right: -8px;
}

.c-header__link {
  margin-right: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-header__logo {
  display: block;
  width: 221px;
  height: 23px;
}

.c-header__overlay {
  position: fixed;
  z-index: 15;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - #{$menu-height});
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: $color-purple;
  transition: opacity 0.4s, visibility 0.4s;
  opacity: 0;
  visibility: hidden;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

// stylelint-disable-next-line
html:not(.is-menu-open) .c-header__overlay {
  pointer-events: none;

  * {
    pointer-events: none;
  }
}

// stylelint-disable-next-line
body.admin-bar .c-header__overlay {
  height: calc(100% - #{80 + 32}px);
}

// stylelint-disable-next-line
html.is-menu-open .c-header__overlay {
  opacity: 1;
  visibility: visible;
  -webkit-overflow-scrolling: touch;
}

// stylelint-disable-next-line
html.is-locked.ie11 .c-header__overlay {
  padding-right: 17px;
}

@supports (color: var(--var)) {
  // stylelint-disable-next-line
  html.is-locked .c-header__overlay {
    padding-right: var(--scroll-width);
  }

  // stylelint-disable
  .c-header__overlay,
  body.admin-bar .c-header__overlay {
    height: calc(var(--inner-height) - #{$menu-height});
  }
  // stylelint-enable
}

.c-header__overlay-inner {
  padding-top: 70px;
  padding-bottom: 70px;
}

.c-header__address {
  margin-top: 55px;
  margin-bottom: 0;
}

.c-header__address-icon {
  width: 34px;
  height: 45px;
  fill: $color-pink-lighter;
  display: block;
  margin-bottom: 10px;
}

.c-header__social {
  margin-top: 55px;
}

.c-header__social-link {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.c-header__social-links {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
}

.c-header__social-icon {
  font-size: 20px;
  width: 1em;
  height: 1em;
  display: block;
}

.c-header__bg-wrapper {
  display: none;
}

@include bp-down(360px) {
  .c-header__logo {
    width: 221*0.65px;
    height: 23*0.65px;
  }
}

@include bp-between(360px, 420px) {
  .c-header__logo {
    width: 221*0.75px;
    height: 23*0.75px;
  }
}

@include bp-between(340px, small) {
  .c-header__button.c-header__button {
    margin-right: 10px;
  }
}

@include bp-down(small) {
  .c-header__button.c-header__button {
    padding: 0.4em 0.5em;
    min-width: 0;
    flex-shrink: 0;
  }
}

@include bp(small) {
  .c-header__button {
    margin-right: 36px;
  }
}

@include bp(medium) {
  .c-header__logo {
    width: 241px;
    height: 25px;
  }

  .c-header__overlay-inner {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .c-header__social,
  .c-header__address {
    margin-top: 65px;
  }

  .c-header__social {
    margin-right: 0;
  }

  .c-header__social-link {
    margin-left: 9px;
    margin-right: 9px;
    margin-top: 13px;
  }

  .c-header__social-links {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -8px;
  }

  .c-header__social-icon {
    font-size: 24px;
  }
}

@include bp(xlarge) {
  $left-column: 540 / $content-width * 100%;

  .c-header__logo {
    width: 281px;
    height: 29px;
  }

  .c-header__overlay-inner {
    display: flex;
    align-items: flex-start;
  }

  .c-header__aside {
    width: #{100% - $left-column};
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .c-header__footer {
    margin-top: 45px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-right: -40px;
    width: 100%;
  }

  .c-header__menu {
    width: $left-column;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .c-header__bg-wrapper {
    position: relative;
    display: block;
    width: 100%;
  }

  .c-header__bg-wrapper:before {
    content: '';
    position: absolute;
    height: 100%;
    left: 100%;
    background-color: #8542d4;
    width: calc(50vw - 600px);
    min-width: 40px;
  }

  .c-header__bg {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .c-header__social,
  .c-header__address {
    margin-top: 0;
  }

  .c-header__address {
    flex-shrink: 0;
    margin-right: auto;
  }

  .c-header__social {
    margin-right: 0;
    margin-left: auto;
    flex-grow: 0;
  }
}
