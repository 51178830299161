/* ==========================================================================
   #BODY
   ========================================================================== */

body {
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

// stylelint-disable
html.is-locked body {
  //overflow-y: scroll;
  overflow: hidden;
}
// stylelint-enable

// stylelint-disable-next-line
html.is-locked.ie11 body {
  padding-right: 17px;
}

@supports (color: var(--var)) {
  // stylelint-disable-next-line
  html.is-locked body {
    padding-right: var(--scroll-width);
  }
}
