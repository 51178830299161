/* ==========================================================================
   #FEATURED
   ========================================================================== */

.c-featured {
  background-color: $color-purple;
  padding-top: 45px;
  padding-bottom: 45px;
}

.c-featured__form {
  width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  display: block;
  max-width: 596px;
}

.c-featured__submit {
  max-width: 170px;
  margin-left: auto;
  margin-right: auto;
}

.c-featured__tile {
  background-color: $color-bg;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  margin-bottom: 50px;
}

.c-featured__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-featured__content {
  padding: 42px 33px;
}

.c-featured__category {
  font-size: 14px;
  letter-spacing: 1.24444px;
  margin-bottom: 13px;
}

.c-featured__title {
  font-size: 22px;
  letter-spacing: -0.2px;
  margin-bottom: 0.5em;
}

.c-featured__text {
  margin-bottom: 1.5em;
}

.c-featured__form-message {
  width: 100%;
  margin-top: 20px;
}

.c-featured__form.is-success .c-featured__form-message {
  color: $color-teal-lighter;
}

.c-featured__form-message:empty {
  display: none;
}

@include bp(medium) {
  .c-featured__form {
    margin-top: -15px;
  }

  .c-featured__form-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .c-featured__form-row {
    width: calc((100% - 15px) / 2);
    margin-bottom: 0;
    margin-top: 15px;
  }

  .c-featured__submit {
    margin-right: 0;
  }

  .c-featured__tile {
    display: flex;
    align-items: stretch;
  }

  .c-featured__media {
    position: relative;
    min-height: 200px;
    width: 290/677 * 100%;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .c-featured__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .c-featured__content {
    padding: 50px 34px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .c-featured__text {
    margin-bottom: 2em;
  }
}

@include bp(large) {
  .c-featured__form {
    margin-top: 0;
    max-width: 1082px;
  }

  .c-featured__form-inner {
    flex-wrap: nowrap;
  }

  .c-featured__form-row {
    width: 290/1082 * 100%;
    flex-shrink: 1;
    flex-grow: 0;
    margin-top: 0;
    margin-right: 15px;
  }

  .c-featured__form-row:last-child {
    width: 167px;
    white-space: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0;
  }

  .c-featured__media {
    width: 620 / 1200 * 100%;
  }
}

@include bp(xlarge) {
  .c-featured {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .c-featured__content {
    padding: 60px 50px;
  }

  .c-featured__category {
    margin-bottom: 17px;
  }

  .c-featured__title {
    font-size: 26px;
  }

  .c-featured__text {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
