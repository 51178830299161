/* ==========================================================================
   #SECTION MASK
   ========================================================================== */

.o-section-mask {
  position: relative;
}

.o-section-mask__bg,
.o-section-mask__bg-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.o-section-mask__bg {
  color: $color-bg;
}

.o-section-mask__content {
  position: relative;
}

.o-section-mask__top,
.o-section-mask__bottom {
  position: absolute;
  width: 100%;
  left: 0;
  display: block;
}

.o-section-mask__top {
  top: -1px;
}

.o-section-mask__bottom {
  bottom: -1px;
}

.o-section-mask--chevron {
  padding-bottom: 60px;
}

.o-section-mask--chevron .o-section-mask__bottom {
  height: 60px;
  min-width: 640px;
  right: 50%;
  left: auto;
  transform: translateX(50%);
}

.o-section-mask--skew .o-section-mask__bottom,
.o-section-mask--skew .o-section-mask__top {
  height: 100px;
}

.o-section-mask--skew .o-section-mask__bottom {
  transform: scale(-1, -1);
}

@include bp-down(medium) {
  .o-section-mask--chevron .o-section-mask__bg {
    overflow: hidden;
  }
}

@include bp(medium) {
  .o-section-mask--chevron {
    padding-bottom: 90px;
  }

  .o-section-mask--chevron .o-section-mask__bottom {
    height: 90px;
    width: 100%;
    right: auto;
    left: 0;
    transform: none;
  }

  .o-section-mask--skew .o-section-mask__bottom,
  .o-section-mask--skew .o-section-mask__top {
    height: 180px;
  }
}

@include bp(xlarge) {
  .o-section-mask--chevron {
    padding-bottom: 166px;
  }

  .o-section-mask--chevron .o-section-mask__bottom {
    height: 166px;
  }

  .o-section-mask--skew .o-section-mask__bottom,
  .o-section-mask--skew .o-section-mask__top {
    height: 260px;
  }
}

@include bp(full) {
  .o-section-mask--skew .o-section-mask__bottom,
  .o-section-mask--skew .o-section-mask__top {
    height: 330px;
  }
}
