/* ==========================================================================
   #ICON
   ========================================================================== */

.o-icon-play {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 52px;
  height: 52px;
  margin-left: -26px;
  margin-top: -26px;
}

.o-icon-corner {
  width: 12px;
  height: 12px;
  display: inline-block;
  fill: $color-teal;
  vertical-align: middle;
  margin-left: 10px;
}

@include bp(large) {
  .o-icon-play:not(.o-icon-play--small) {
    width: 72px;
    height: 72px;
    margin-left: -36px;
    margin-top: -36px;
  }
}
