/* ==========================================================================
   #INPUT
   ========================================================================== */

.o-input {
  display: block;
  position: relative;
  color: $color-purple;
  margin-bottom: 15px;
}

.o-input-submit {
  line-height: normal;
  height: 45px;
  font-size: 17px;
  font-weight: $font-bold;
  text-align: center;
  padding: 10px 1em;
  border-radius: 2px;
  background-color: $color-teal;
  color: $color-white;
  transition: background-color 0.3s;
  display: block;
  width: 100%;

  @include link-hover {
    background-color: $color-pink;
  }
}

.o-input__input {
  border: 1px solid currentColor;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: transparent;
  line-height: normal;
  font-size: 17px;
  padding: 10px 1em;
  display: block;
  width: 100%;
  height: 45px;
  outline: none;
  transition: border-color 0.3s;
}

.o-input__input.is-invalid {
  border-color: $color-pink;
}

.o-input__label {
  font-size: 17px;
  padding: 10px 1em;
  line-height: normal;
  letter-spacing: -(0.2/17) + em;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
}

.o-input__input[data-value]:not([data-value='']) + .o-input__label,
.o-input__input:valid + .o-input__label,
.o-input__input:focus + .o-input__label {
  display: none;
}

.o-input__input:focus {
  border-color: $color-teal;
}

.o-input__input[required] + .o-input__label:after {
  content: '*';
  color: $color-pink;
}

.o-input--white .o-input__input,
.o-input--white .o-input__label {
  color: $color-grey-light;
}

.o-input--white .o-input {
  border-color: $color-white;
}
