/* ==========================================================================
   #BUTTON
   ========================================================================== */

.o-btn {
  border: 2px solid currentColor;
  border-radius: 3px;
  line-height: 22/16;
  font-weight: $font-bold;
  transition: color 0.3s;
  min-width: 105px;
  padding: 9px 15px;
  text-align: center;
}

.o-btn:not(input):not(button) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
