/* ==========================================================================
   #CURRENT EVENTS
   ========================================================================== */

.c-current-events {
  margin-bottom: 10px;
}

.c-current-events--subpage {
  margin-bottom: 0;
  padding-bottom: 20px;
}

.c-current-events__title {
  margin-bottom: 0.2em;
}

.c-current-events__month {
  margin-bottom: 30px;
}

.c-current-events__link {
  margin-bottom: 75px;
}

// stylelint-disable-next-line
html.ie .c-current-events__link {
  opacity: 0.9999;
}

.c-current-events__items {
  margin: -40px -13px 0;
  max-width: 620px;
}

.c-current-events__item {
  border-top: 1px solid currentColor;
  padding: 40px 13px 40px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.c-current-events__item:first-child {
  border-top: none;
}

.c-current-events__day {
  width: 80px;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-current-events__day-name {
  margin-bottom: 5px;
}

.c-current-events__day-number {
  font-size: 55px;
  line-height: 1;
  margin-bottom: 0;
}

.c-current-events__events {
  margin-top: 35px;
  margin-bottom: 0;
}

.c-current-events__event {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.c-current-events__time {
  width: 75px;
  padding-right: 5px;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-current-events__bg {
  background-color: #fae9e8;
}

@include bp(medium) {
  .c-current-events {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .c-current-events--subpage {
    margin-bottom: 0;
    padding-bottom: 40px;
    padding-top: 200px;
  }

  .c-current-events__items {
    margin-left: auto;
    margin-right: auto;
  }

  .c-current-events__item {
    padding: 40px 74px;
  }

  .c-current-events__day {
    width: 118px;
  }

  .c-current-events__time {
    width: 90px;
  }
}

@include bp(large) {
  .c-current-events__items {
    max-width: 1114px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .c-current-events__item {
    width: 500 / 1114 * 100%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .c-current-events__item:nth-child(2) {
    border-top: none;
  }
}

@include bp(xlarge) {
  .c-current-events {
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .c-current-events--subpage {
    margin-bottom: 0;
    padding-bottom: 80px;
    padding-top: 100px;
  }

  .c-current-events__link {
    margin-bottom: 110px;
  }
}
