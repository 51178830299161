/* ==========================================================================
   #SHARE
   ========================================================================== */

.c-share {
  display: flex;
}

.c-share__link {
  display: flex;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.c-share__icon {
  display: block;
}

.c-share__icon--linkedin {
  margin-bottom: 3px;
}
