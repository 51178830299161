/* ==========================================================================
   #ICON BUTTON
   ========================================================================== */

.o-icon-button {
  box-shadow: -1px 1px 5px rgba($color-black, 0.160666);
  border-radius: 3px;
  padding: 25px 20px (25 + 18) + px ;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.o-icon-button--one-line {
  padding: 12px (20 + 12 + 18) + px 12px 20px;
}

.o-icon-button__icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 18px;
  flex-grow: 0;
  display: block;
}

.o-icon-button__content {
  flex-grow: 1;
  flex-shrink: 1;
  width: calc(100% - 68px);
}

.o-icon-button__title {
  margin-bottom: 6px;
}

.o-icon-button__text {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.o-icon-button__arrow {
  display: block;
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: 0;
  position: absolute;
  bottom: 18px;
  right: 20px;
}

@include bp-down(large) {
  .o-icon-button--small {
    padding: 8px (14 + 8 + 11) + px 8px 14px;
  }

  .o-icon-button--small  .o-icon-button__arrow {
    width: 8px;
    height: 8px;
    right: 14px;
    bottom: 14px;
  }

  .o-icon-button--small .o-icon-button__icon {
    width: 35px;
    height: 35px;
    margin-right: 11px;
  }
}
