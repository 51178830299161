/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav,
.c-main-nav__dropdown {
  margin-bottom: 0;
  margin-top: 0;
}

.c-main-nav__item,
.c-main-nav__subitem {
  margin-bottom: 0;
}

.c-main-nav__trigger {
  padding: 0 6px;
  transition: opacity 0.3s, transform 0.3s;
  outline: none;
}

.c-main-nav__trigger.is-active {
  opacity: 0;
  transform: rotate(-80deg) scale(0.3);
  pointer-events: none;
}

.c-main-nav__dropdown {
  position: relative;
  transition: max-height 0.4s;
  overflow: hidden;
  max-height: 0;
}

.c-main-nav__item.is-active .c-main-nav__dropdown {
  max-height: 300px;
}

.c-main-nav__subitem:last-child {
  padding-bottom: 30px;
}
