/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  position: relative;
}

.c-footer__inner {
  padding: 70px 10px 40px;
}

.c-footer__link {
  display: block;
  width: 150px;
  height: 68px;
  margin-bottom: 40px;
}

.c-footer__logo {
  display: block;
  width: 100%;
  height: auto;
}

.c-footer__header,
.c-footer__bottom {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}

.c-footer__menu {
  margin-bottom: 40px;
  display: flex;
  margin-right: -20px;
}

.c-footer__submenu {
  margin-bottom: 0;
  margin-top: 1em;
}

.c-footer__menu-subitem {
  margin-bottom: 1em;
}

.c-footer__menu-item {
  width: 50%;
  margin-right: 20px;
  margin-bottom: 0;
}

.c-footer__address {
  margin-bottom: 40px;
  letter-spacing: -0.2px;
}

.c-footer__address p {
  margin-bottom: 0.5em;
}

.c-footer__separator {
  height: 1px;
  border: none;
  background-color: rgba(#464747, 0.14);
  margin-bottom: 40px;
  margin-left: -23px;
  margin-right: -23px;
}

.c-footer__social {
  display: flex;
  font-size: 20px;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 30px;
}

.c-footer__social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.c-footer__social-icon {
  width: 1em;
  height: 1em;
  display: block;
  margin: auto;
}

.c-footer__copyright {
  line-height: 27px;
  font-size: 14px;
  letter-spacing: -0.2px;
  margin-right: -10px;
}

@include bp(medium) {
  .c-footer__link {
    width: 122px;
    height: 56px;
  }

  .c-footer__header {
    display: flex;
    justify-content: space-between;
  }

  .c-footer__menu-item {
    margin-right: 0;
    width: auto;
    max-width: 49%;
  }

  .c-footer__menu {
    margin-right: 0;
    justify-content: space-between;
    width: 237 / 660 * 100%;
    flex-shrink: 0;
  }

  .c-footer__separator {
    margin-left: 0;
    margin-right: 0;
  }

  .c-footer__social {
    font-size: 22px;
    margin-left: -9px;
    margin-top: -9px;
    margin-bottom: 21px;
  }

  .c-footer__social-link {
    margin-right: 3px;
  }

  .c-footer__copyright {
    font-size: 15px;
  }
}

@include bp(large) {
  .c-footer__inner {
    padding-left: 0;
    padding-right: 0;
  }

  .c-footer__address {
    width: 260 / 1080 * 100%;
    margin-bottom: 0;
  }

  .c-footer__logo {
    margin-bottom: 0;
  }

  .c-footer__header {
    margin-bottom: 55px;
  }

  .c-footer__menu {
    width: 372 / 1080 * 100%;
    margin-bottom: 0;
  }
}

@include bp(xlarge) {
  .c-footer__link {
    width: 150px;
    height: 68px;
  }
}

@include bp(full) {
  .c-footer__separator {
    width: 1330px;
    margin-left: -#{(1330 - $content-width) / 2}px;
    margin-right: 0;
  }
}
