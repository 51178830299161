/* ==========================================================================
   #BLOCKQUOTE
   ========================================================================== */

.o-blockquote {
  font-size: 30px;
  font-weight: 700;
  line-height: 56px;
  color: $color-white;
  margin-left: 16px;
  margin-right: 16px;

  p {
    box-decoration-break: clone;
    display: inline;
    background-color: $color-purple;
    box-shadow: -16px 0 0 5px $color-purple, 16px 0 0 5px $color-purple;
  }
}

@include bp(medium) {
  .o-blockquote {
    font-size: 35px;
    line-height: 60px;

    p {
      box-shadow: -16px 0 0 2px $color-purple, 16px 0 0 2px $color-purple;
      border-bottom: 4px solid $color-purple;
    }
  }
}

@include bp(large) {
  .o-blockquote {
    margin-left: -165px;
    margin-right: 10px;
  }
}
