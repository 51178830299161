/* ==========================================================================
   #BR
   ========================================================================== */

@include bp-down(small) {
  .o-no-br-small br {
    display: none;
  }
}

@include bp-down(medium) {
  .o-no-br-medium br {
    display: none;
  }
}

@include bp-down(large) {
  .o-no-br-large br {
    display: none;
  }
}

@include bp-down(xlarge) {
  .o-no-br-xlarge br {
    display: none;
  }
}
