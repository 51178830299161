/* ==========================================================================
   #BLOCK
   ========================================================================== */

.o-block {
  margin-bottom: 40px;
}

.o-block__cta,
.o-block__text,
.o-block__title {
  margin-bottom: 15px;
}

.o-block__cta {
  max-width: 270px;
  width: 100%;
}

.o-block__column {
  max-width: 660px;
}

.o-block__sidebar:empty {
  display: none;
}

.c-block__sidebar-text {
  max-width: 210px;
  padding-right: 10px;

  hr {
    margin-right: -10px;
  }
}

@include bp(medium) {
  .o-block {
    margin-bottom: 50px;
  }

  .o-block__text {
    margin-bottom: 25px;
  }
}

@include bp(xlarge) {
  .o-block__columns {
    display: flex;
    align-items: flex-start;
  }

  .o-block__column {
    width: calc(100% - 300px);
  }

  .o-block__sidebar {
    width: 270px;
    margin-left: auto;
    margin-top: 2px;
  }
}
