/* ==========================================================================
   #HEADING
   ========================================================================== */

.u-h-hero {
  font-size: 86px;
  letter-spacing: -(4.87/94) + em;
  line-height: 1;
  text-shadow: 0 3px 14px rgba($color-black, 0.2);
}

.u-h-headline {
  font-size: 29px;
  line-height: 35px;
}

.u-h-blog-headline {
  font-size: 37px;
  line-height: 46px;
}

.u-h-blog-title {
  font-size: 46px;
  line-height: 50px;
}

.u-h-tag {
  font-size: 35px;
  line-height: 45px;
}

.u-h1 {
  font-size: 39px;
  line-height: 1;
}

.u-h2 {
  font-size: 39px;
}

.u-h3 {
  font-size: 39px;
}

.u-h4 {
  font-size: 39px;
  letter-spacing: -(1.27/39) + em;
}

.u-h5 {
  font-size: 30px;
  line-height: 37px;
}

.u-h6 {
  font-size: 20px;
  line-height: 27px;
}

@include bp(xsmall) {
  .u-h-hero {
    font-size: 94px;
  }
}

@include bp(medium) {
  .u-h-hero {
    font-size: 140px;
  }

  .u-h-headline {
    font-size: 39px;
    line-height: 44px;
  }

  .u-h-blog-headline {
    font-size: 57px;
    line-height: 62px;
  }

  .u-h-blog-title {
    font-size: 75px;
    line-height: 85px;
  }

  .u-h1 {
    font-size: 75px;
    line-height: 85px;
  }

  .u-h2 {
    font-size: 55px;
  }

  .u-h3 {
    font-size: 48px;
  }

  .u-h5 {
    font-size: 30px;
    line-height: 37px;
  }
}

@include bp(xlarge) {
  .u-h-headline {
    font-size: 48px;
    line-height: 54px;
  }

  .u-h-blog-headline {
    font-size: 57px;
    line-height: 62px;
  }

  .u-h2 {
    font-size: 75px;
  }

  .u-h4 {
    font-size: 43px;
  }

  .u-h5 {
    font-size: 35px;
    line-height: 45px;
  }
}
