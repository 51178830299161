/* ==========================================================================
   #TESTIMONIALS
   ========================================================================== */

.c-testimonials {
  margin-top: 30px;
  margin-bottom: 20px;
}

.c-testimonials__title {
  margin-bottom: 10px;
}

.c-testimonials__text {
  margin-bottom: 1em;
}

.c-testimonials__item {
  margin-top: 60px;
}

.c-testimonials__bg {
  object-position: 20% top;
}

.c-testimonials__inner {
  padding-top: 10px;
  padding-bottom: 90px;
}

.c-testimonials__author {
  margin-bottom: 0.5em;
}

.c-testimonials__quote {
  margin-bottom: 0.75em;
}

.c-testimonials__links {
  display: flex;
}

.c-testimonials__link {
  margin-bottom: 0;
}

.c-testimonials__link + .c-testimonials__link:before {
  content: '|';
  font-weight: $font-regular;
  color: $color-white;
  margin-left: 0.75em;
  margin-right: 0.75em;
}


@include bp(medium) {
  .c-testimonials__inner {
    padding-top: 50px;
    padding-bottom: 200px;
  }

  .c-testimonials__title {
    margin-bottom: 40px;
  }

  .c-testimonials__text {
    margin-bottom: 1.2em;
  }

  .c-testimonials__items {
    margin-top: 140px;
    margin-left: auto;
    margin-right: 0;
    max-width: 650px;
  }

  .c-testimonials__item {
    margin-top: 80px;
    display: flex;
    align-items: flex-start;
  }

  .c-testimonials__image {
    margin-right: 25px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .c-testimonials__quote {
    margin-bottom: 1em;
  }
}

@include bp(large) {
  .c-testimonials__items {
    margin-top: 105px;
  }
}

@include bp(xlarge) {
  .c-testimonials__inner {
    padding-top: 100px;
    padding-bottom: 300px;
  }

  .c-testimonials__text {
    margin-bottom: 1.6em;
  }
}

@include bp($content-bp) {
  .c-testimonials__items {
    margin-left: calc(50% - 79px);
  }
}

@include bp(full) {
  .c-testimonials__inner {
    padding-bottom: 420px;
  }
}
