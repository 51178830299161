/* ==========================================================================
   #PARDOT FORM
   ========================================================================== */

.c-pardot-form {
  .btn,
  [type='submit'] {
    border-radius: 2px;
    background-color: #01b3ad;
    color: $color-white;
    font-weight: bold;
    min-height: 45px;
    text-align: center;
    font-size: 17px;
    letter-spacing: -0.2px;
    padding: 0.5em 1em;
    width: 100%;
    transition: color 0.3s, background-color 0.3s;

    &:not(button):not(input) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    @include link-hover {
      background-color: $color-pink;
    }
  }

  .submit.submit {
    margin: 40px 0 0 0;
  }

  .form-field {
    margin-left: 0;
    color: $color-purple;
    position: relative;

    &.no-label {
      margin-left: 0;
    }

    .field-label {
      color: currentColor;
    }
  }

  .required .field-label {
    &:after {
      content: '*';
      color: $color-pink;
    }
  }

  input {
    max-width: 100%;
  }

  .form-field > input {
    width: 100%;
  }

  // stylelint-disable
  .form-field > input,
  .form-field > input + .field-label {
    font-size: 18px;
    font-weight: 700;
    color: currentColor;
    padding-left: 1em;
    padding-right: 1em;
    line-height: normal;
  }

  .form-field > input {
    border-radius: 2px;
    border: 1px solid currentColor;
    height: 54px;
    position: relative;
  }

  .form-field > input + .field-label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .form-field > input[data-value]:not([data-value='']) + .field-label,
  .form-field > input:focus + .field-label {
    display: none;
  }

  .pd-checkbox,
  .pd-radio {
    font-size: 16px;

    .value {
      position: relative;
      display: block;
    }

    .value input {
      position: absolute;
      right: 100%;
      top: 0;
      visibility: hidden;
      appearance: none;
      pointer-events: none;
    }

    .value label {
      position: relative;
      padding-left: 30px;
      min-height: 18px;
      display: block;

      &:before {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        border: 1px solid currentColor;
        position: absolute;
        left: 0;
        top: 0;
        transition: box-shadow 0.3s, background-color 0.3s;
      }
    }

    .value input:checked + label {
      &:before {
        opacity: 1;
        background-color: $color-pink;
        box-shadow: inset 0 0 0 3px $color-white;
      }
    }
  }

  .errors {
    color: $color-purple;
  }

  .error.no-label {
    color: $color-pink;
  }

  .error.no-label {
    margin-left: 0;
  }
}

.c-pardot-form__inner {
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 40px);
}
