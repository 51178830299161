/* ==========================================================================
   # POPUP
   ========================================================================== */

.c-popup {
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - #{$menu-height});
  background-color: rgba($color-teal, 0.95);
  position: fixed;
  z-index: 5;
  overflow-y: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

// stylelint-disable-next-line
html.is-locked.ie11 .c-popup {
  padding-right: 17px;
}

@supports (color: var(--var)) {
  // stylelint-disable-next-line
  html.is-locked .c-popup {
    padding-right: var(--scroll-width);
  }

  .c-popup {
    height: calc(var(--inner-height) - #{$menu-height});
  }
}

.c-popup--preview {
  position: relative;
  top: 0;
  left: 0;
  height: auto;
}

.c-popup:not(.is-active) {
  pointer-events: none;

  * {
    pointer-events: none;
  }
}

.c-popup.is-active {
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  visibility: visible;
}

.c-popup__inner {
  width: calc(100% - 34px);
  max-width: 680px;
  margin: auto;
  position: relative;
  border-radius: 2px;
  background: $color-white url('../assets/images/pic-pattern.svg') no-repeat top center;
  background-size: 670px 10px;
  padding-top: 10px;
  box-shadow: 2px 4px 18px rgba(35, 35, 37, 0.1);
}

.c-popup--video .c-popup__inner {
  max-width: $content-width + px;
}

.c-popup__content {
  margin: 60px 10px 30px;
}

.c-popup--video .c-popup__content {
  position: relative;
  margin: 40px 0 0 0;
  padding-top: (9/16) * 100%;
  background-color: $color-black;
}

.c-popup__close {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  top: -28px;
  left: 50%;
  margin-left: -28px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.17);
  line-height: 1;
  z-index: 1;
}

.c-popup__close-icon {
  display: block;
  margin: auto;
  vertical-align: middle;
}

.c-popup__footer {
  min-height: 40px;
  padding-bottom: 30px;
  padding-top: 10px;
}

.c-popup--video .c-popup__footer {
  padding-bottom: 10px;
  min-height: 45px;
}

.c-popup__close-footer {
  font-size: 14px;
}

.c-popup .c-popup__video.c-popup__video {
  background-color: $color-black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.c-popup .c-popup__content iframe {
  display: none;
}

.c-popup.is-visible .c-popup__content iframe {
  display: block;
}

@media (orientation: landscape) {
  .c-popup--video .c-popup__inner {
    max-height: calc(100vh - #{80 + 100}px);
    width: calc((100vh - #{80 + 100 + 45 + 50}px) * #{16/9});
  }
}

@include bp(400px) {
  .c-popup__content {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@include bp(700px) {
  .c-popup__inner {
    background-size: 100% 10px;
  }

  .c-popup__content {
    margin-left: 50px;
    margin-right: 50px;
  }
}
