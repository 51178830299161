/* ==========================================================================
   #COLUMNS
   ========================================================================== */

.c-columns {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-left: -15px;
}

.c-columns__column {
  width: calc(50% - 10px);
  margin-left: 10px;
  margin-bottom: 15px;
}

.c-columns--image .c-columns__column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.c-columns__image {
  object-position: left center;
  max-width: 180px;
  max-height: 72px;
  width: 100%;
  height: auto;
}

.c-columns__title {
  margin-bottom: 0;
}

@include bp-down(xlarge) {
  .o-block__sidebar {
    margin-top: 15px;
  }
}

@include bp(medium) {
  .c-columns__image {
    max-width: 200px;
    max-height: 92px;
  }

  .c-columns--image .c-columns__column,
  .c-columns__column {
    width: calc(100% / 3 - 15px);
  }

  .c-columns--four .c-columns__column {
    width: calc(25% - 15px);
  }
}

@include bp(large) {
  .c-columns__column {
    padding-right: 30px;
  }
}
