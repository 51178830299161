/* ==========================================================================
   #TWITTER
   ========================================================================== */

.o-twitter {
  word-wrap: break-word;

  .invisible {
    display: none;
  }

  a {
    @extend .o-link-teal;
  }

  .Emoji--forText {
    height: 1.25em;
    width: 1.25em;
    padding: 0 0.05em 0 0.1em;
    vertical-align: -0.2em;
  }
}
