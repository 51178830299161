///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'Open Sans', sans-serif !default;

$font-bold: 700;
$font-regular: 400;
$font-semi-bold: 600;

/* Sizes
   ========================================================================== */

$content-width: 1240;
$content-large-width: 1440;
$menu-height: 80px;


/* Breakpoints
   ========================================================================== */

$breakpoints: (
  xsmall: 375px,
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
  full: 1400px,
);

$content-bp: ($content-width + 80) * 1px;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;

$color-purple: #6613c9;
$color-dark: #31333d;
$color-teal: #01b3ad;
$color-teal-lighter: #81f7f3;
$color-grey-dark: #787777;
$color-grey: #aeafb4;
$color-grey-lighter: #e6e6d9;
$color-grey-light: #f3f2ef;
$color-pink: #ff80ff;
$color-pink-lighter: #f7aecb;
$color-pink-light: #e1d1eb;

// Text
$color-bg: $color-white;
$color-text: $color-dark;

/* CSS variables
   ========================================================================== */

:root {
  --initial-height: 100vh;
  --window-height: 100vh;
  --scroll-width: 0;
  --admin-bar: 32px;
}
