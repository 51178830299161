/* ==========================================================================
   #TEXT
   ========================================================================== */

.o-text {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: inherit;

    &:first-child {
      margin-top: 0;
    }
  }

  h1 {
    font-size: 35px;
    line-height: 45px;
  }

  h2 {
    font-size: 30px;
    line-height: 37px;
  }

  h3 {
    font-size: 24px;
    line-height: 34px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  p, ul, ol, blockquote, iframe, hr {
    margin-bottom: 22px;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 24px;
  }

  blockquote {
    @extend .o-blockquote;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #e5e5e5;
  }

  ul {
    list-style: none;
    margin-left: 0;

    li {
      position: relative;
      padding-left: 1.2em;
    }

    li:before {
      content: '•';
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
    min-height: 200px;
    border: none;
    overflow-x: hidden;
  }

  iframe:not([width]) {
    width: 100%;
  }
}

@include bp(medium) {
  .o-text {
    blockquote {
      margin-top: 30px;
      margin-bottom: 34px;
    }

    h1 {
      font-size: 39px;
      line-height: 47px;
    }
  }
}

@include bp(large) {
  .o-text {
    h1 {
      font-size: 48px;
      line-height: 54px;
    }

    h2 {
      font-size: 35px;
      line-height: 45px;
    }
  }
}
