/* ==========================================================================
   #QUOTE
   ========================================================================== */

.c-quotes__item {
  text-align: center;
  margin-bottom: 35px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.c-quotes__inner {
  margin-top: 35px;
}

.c-quotes__content {
  text-align: left;
}

.c-quotes__quote {
  line-height: 28px;
  font-size: 22px;
  margin-bottom: 20px;
}

.c-quotes__link {
  font-size: 14px;
  letter-spacing: 0.9px;
}

.c-quotes__media {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

@include bp(medium) {
  .c-quotes__item {
    text-align: left;
    margin-left: 0;
    max-width: none;
    display: flex;
    align-items: flex-start;
  }

  .c-quotes__media {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 35px;
  }

  .c-quotes__quote {
    line-height: 24px;
    font-size: 18px;
    margin-bottom: 15px;
  }
}

@include bp(large) {
  .c-quotes__quote {
    line-height: 28px;
    font-size: 22px;
    margin-bottom: 20px;
  }
}

@include bp(xlarge) {
  .c-quotes__item:last-child {
    margin-bottom: 0;
  }
}
