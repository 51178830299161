/* ==========================================================================
   #HAMBURGER
   ========================================================================== */

.o-hamburger__wrapper {
  display: block;
  width: 25px;
  height: 23px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.o-hamburger__bar {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: currentColor;
  opacity: 1;
  right: 0;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out, width 0.25s ease-in-out, height 0.25s ease-in-out, top 0.25s ease-in-out, right 0.25s ease-in-out;
  border-radius: 1.5px;
}

.o-hamburger__bar:nth-child(1) {
  top: 0;
}

.o-hamburger__bar:nth-child(2), .o-hamburger__bar:nth-child(3) {
  top: 10px;
  width: 20px;
}

.o-hamburger__bar:nth-child(4) {
  top: 20px;
}

.is-menu-open .o-hamburger__bar:nth-child(1) {
  top: 10px;
  width: 0;
  right: 50%;
}

.is-menu-open .o-hamburger__bar:nth-child(2), .is-menu-open .o-hamburger__bar:nth-child(3) {
  width: 30px;
  right: -3px;
}

.is-menu-open .o-hamburger__bar:nth-child(2) {
  transform: rotate(45deg);
}

.is-menu-open .o-hamburger__bar:nth-child(3) {
  transform: rotate(-45deg);
}

.is-menu-open .o-hamburger__bar:nth-child(4) {
  top: 10px;
  width: 0;
  right: 50%;
}
