/* ==========================================================================
   #COLOR
   ========================================================================== */

.u-fill-cc {
  fill: currentColor;
}

.u-pink {
  color: $color-pink;
}

.u-pink-light {
  color: $color-pink-light;
}

.u-pink-lighter {
  color: $color-pink-lighter;
}

.u-purple {
  color: $color-purple;
}

.u-teal {
  color: $color-teal;
}

.u-teal-lighter {
  color: $color-teal-lighter;
}

.u-white {
  color: $color-white;
}

.u-grey {
  color: $color-grey;
}

.u-grey-dark {
  color: $color-grey-dark;
}

.u-dark {
  color: $color-dark;
}
